import React from 'react'

const Loader = () => {
    return (
        <div>
            <div className="loader-box">
                <div className="loader-3" />
            </div>
        </div>
    )
}

export default Loader
