import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ConfirmationModal(props) {
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const {t} = useTranslation()

    return (
        <div>

            <Modal

                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.ModalOpen}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.ModalOpen}>
                    <Box sx={style} style={{ border: 'none' }}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {props.title}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            {props.message}
                        </Typography>
                        <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-secondary" style={{marginRight:'5px'}} onClick={props.onClose} >
                                {t("Cancel")}
                            </button>
                            <button style={{marginRight:'5px'}} type='button' className="btn btn-primary" onClick={props.onSubmit}>
                                {t("Agree")}
                            </button>
                            
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
