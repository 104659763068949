import React, { useState } from 'react'
import { EmployeeType } from '../../pages/JobPost/jobDetails'

const ExperienceCard = ({ jobTitle, company, jobType, startDate, endDate, description, currentlyWorking }) => {
    

    return (
        <div className='card  py-4 px-3'>
            <div className="row">

                <div className='col-9'>
                    <h6>
                        {jobTitle}
                    </h6>
                    <p>{company} - {jobType}</p>
                </div>

                <div className='justify-content-end d-flex col-3 align-items-center'>
                    <p>{startDate} - {endDate ? endDate : currentlyWorking && 'Present'}</p>
                </div>

                <div className={`col-12 `} >
                    <p className='my-2'>description:</p>
                    <p >{description}</p>



                </div>
            </div>

        </div>
    )
}

export default ExperienceCard