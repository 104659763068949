import React, { useContext, useEffect, useState } from 'react'
import BackButtonComponent from '../../components/backButtonComponent/backButtonComponent'
import { Context } from '../../components/context/context'
import Layout from '../../components/Layout/Layout'
import Loader from '../../components/Loader/Loader'
import { url } from '../../Functionalities/config'
import { ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'
import SettingsForm from './settingsForm'
import { useTranslation } from 'react-i18next'

const Settings = () => {
    const { token } = useContext(Context)
    const { t } = useTranslation()

    const [data, setdata] = useState([
        // { id: 1, name: 'one_signal', description: 'description', value: 963852741 },
        // { id: 1, name: 'msg91', description: 'description', value: 963852741 },
        // { id: 1, name: 'Google Api key', description: 'description', value: 963852741 },
        // { id: 1, name: 'Google Api key', description: 'description', value: 963852741 },
    ])
    const [loading, setLoading] = useState(true)



    useEffect(() => {
        token && getSettingData()
    }, [token])

    const getSettingData = () => {
        // alert('calling')
        var axios = require('axios');

        var config = {
            method: 'get',
            url: url + '/settings/',
            headers: {
                'Authorization': `Token ${token}`,
            },

        };

        axios(config)
            .then(function (response) {
                console.log((response));
                if (response.data.results) {
                    setdata(response.data.results)
                    setLoading(false)
                    
                }
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false)
            });

    }


    // console.log(data)
    return (
        <div>
            <>
                <div className="card">
                    {/* <BackButtonComponent /> */}
                    {data.length ?
                        <>
                            <div className="card-header pb-0">
                                <h5>{t("Settings")}</h5>
                            </div>



                            <form className="form theme-form">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            {data.map(settings => (
                                                <SettingsForm data={settings} API={getSettingData}
                                                />

                                            ))}

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </>
                        :
                        <>
                            {loading ?
                                <Loader /> :
                                <div className="d-flex justify-content-center m-50">
                                    <h6>{t("No Data Found")}</h6>
                                </div>
                            }
                        </>
                    }
                </div>

            </>
        </div>
    )
}

export default Settings
