import { Chip } from '@mui/material'
import React from 'react'

const InputField = (props) => {

    return (
        <div className='row'>
            <div className='col-3 ' >
                <label className='col-form-label'>{props.label} {props.isRequired ? '*' : ''}</label>
            </div>

            <div className='col-9'>
                {props.type !== 'textarea' &&
                    <input
                        className='form-control'
                        type={props.type}
                        name={props.name}
                        placeholder={props.placeholder}
                        onChange={props.onchange}
                        value={props.value}
                        disabled={props.disabled}
                        id={props.id}
                        multiple={props.multiple}
                        onKeyDown={props.onKeyDown}
                        accept={props.acceptFile}
                        onWheel={(e) => props.type == 'number' ? e.target.blur() : null}

                    />
                }

                {props.type == 'file' &&
                    <div className='d-flex'>
                        <button type="button" className="btn btn-success mb-1" onClick={() => document.querySelector(`#${props.id}`).click()}>
                            {props.buttonName}</button>

                        {props.filename &&

                            <div style={{ marginLeft: '1rem' }}>
                                <Chip
                                    label={props.filename}
                                // onClick={() => window.open(URL.createObjectURL(props.value), '_blank')}
                                // onDelete={() => setdata({ ...data, resume: '' })}
                                />
                            </div>
                        }
                    </div>
                }
                {props.type == 'textarea' &&
                    <textarea className='form-control' disabled={props.isDisabled} name={props.name} placeholder={props.placeholder} onChange={props.onchange} rows={props.rows} value={props.value} />
                }
            </div>
        </div>
    )
}

export default InputField