import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import styled from '@emotion/styled'
import { Box } from '@mui/system'
import moment from 'moment'




const AdminHandledUsers = () => {
    const { t } = useTranslation()
    const { state, pathname } = useLocation()
    const history = useHistory()

    const [data, setdata] = useState([])







    useEffect(() => {

        if (state?.userData) {
            setdata(state.userData)
        }

        console.log({ state })

        return (() => {
            setdata([])
        })

    }, [state])


    return (
        <>
            <div class="card ">
                <div class="d-flex justify-content-between card-header pb-0 ">
                    <h5>{pathname.replaceAll('/', ' ').replaceAll('-', ' ')} </h5>
                    <div>
                        {/* <button class="btn btn-primary " onClick={() => setIsModalOpen(true)} >Filter</button> */}
                        {/* <button className="btn btn-outline-primary" style={{ marginLeft: '.5rem' }} onClick={() => { setIsExportModalOpen(true); GetUsers('&pagination=0') }} >Export</button> */}
                    </div>
                </div>



                <div className="row card-body">

                    <div className="d-flex justify-content-between">
                        {/* <div>
                            
                            {((FilterData?.applied_users.length || FilterData?.matching_users.length || FilterData?.shortlisted_users.length || FilterData?.id || FilterData?.username || FilterData?.mobile) &&
                                <div className="d-flex align-items-center">

                                    <p className="mb-0">FilterData Applied</p>
                                    <button className="font-primary btn  m-0" onClick={() => { setFilterData({ ...FilterData, shortlisted_users: '', applied_users: '', username: '', id: '', mobile: '', matching_users: [] }); setClearFilterData(true); settitle('Users') }}>
                                        <i className="fas fa-times-circle " style={{ marginRight: '.25rem' }}></i>
                                        Clear FilterData
                                    </button>
                                </div>
                            )}
                        </div> */}


                        {/* <div>
                            <button className='btn btn-primary' disabled={!Prev ? true : false} onClick={HandlePrev}>Prev </button>
                            {` ${currentPage}/${TotalPages}`}
                            <button className='btn btn-primary ' style={{ marginLeft: '.25rem' }} disabled={!Next ? true : false} onClick={HandleNext}> Next</button>
                        </div> */}
                    </div>
                    <div className="table-responsive custom-scrollbar">

                        <div id="basic-3_wrapper" className="dataTables_wrapper">


                            <table className="display dataTable" id="basic-3" role="grid" aria-describedby="basic-3_info">
                                <thead>
                                    <tr role="row">

                                        <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '75.8875px' }}>
                                            {("Id")} </th>
                                        <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '275.8875px' }}>
                                            {("Name")} </th>
                                        <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                            {("Email")}</th>
                                        <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                            {("Mobile")}</th>
                                        <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '157.337px' }}>
                                            {("Job Post")}</th>
                                        <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '157.337px' }}>
                                            {("Job Designation")}</th>
                                        <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '157.337px' }}>
                                            {("Join Date")}</th>


                                        <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '120px' }}>
                                            {("")}

                                        </th>

                                    </tr>


                                </thead>



                                <tbody>
                                    {data.map((item) => (
                                        <tr role="row" className="odd ">

                                            <td>{item.jobpostuser.id}</td>
                                            <td>{`${item.jobpostuser.user.first_name && item.jobpostuser.user.first_name}  ${item.jobpostuser.user.middle_name !== null ? item.jobpostuser.user.middle_name : ''}  ${item.jobpostuser.user.last_name && item.jobpostuser.user.last_name}`}  </td>
                                            <td>{item.jobpostuser.user.email}</td>
                                            <td>{item.jobpostuser.user.google_id == item.jobpostuser.user.mobile ? '' : item.jobpostuser.user?.facebook_id == item.jobpostuser.user.mobile ? '' : item.jobpostuser.user?.linkedin_id == item.jobpostuser.user.mobile ? '' : item.jobpostuser.user.mobile}</td>
                                            {/* <td>{item.mobile}</td> */}


                                            <td>{item.jobpostuser.job.job_name}</td>
                                            <td>{item.jobpostuser.user.current_designation}</td>
                                            <td>{moment(item.jobpostuser.user.date_joined).format('MMMM Do YYYY')}</td>




                                            <td className='d-flex justify-content-center'>
                                                <ColorButton size='small' variant="contained" color='secondary' onClick={() => history.push({ pathname: `/user-details/${item.jobpostuser.user.id}`, state: { data: item.jobpostuser.user } })} >
                                                    View
                                                </ColorButton>
                                            </td>



                                        </tr>
                                    ))}
                                </tbody>





                            </table>






                        </div>
                    </div>
                </div>
            </div>
        </ >
    )
}

export default AdminHandledUsers


export const ColorButton = styled(Button)(({ }) => ({
    // color: theme.palette.getContrastText('#2c245d'),
    backgroundColor: '#2c245d',
    '&:hover': {
        backgroundColor: '#2c245d',
        color: '#dedede'
    },
}));