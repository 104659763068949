import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { protocol } from '../../Functionalities/config'
import { ShowSuccessMessage, ShowFailedMessage } from '../../Functionalities/functions'
import Loader from '../../components/Loader/Loader'

import CustomModal from '../../components/Modal/CustomModal'
import { ManagePagination } from '../../Functionalities/PaginationLogic'
import { validateEmail } from '../../Functionalities/functions'
import ConfirmationModal from '../../components/Modal/confirmationModal'
import { Download } from '../Export/download'
import { ManageAPIs } from '../../Functionalities/Logic'
import { Autocomplete, Button, TextField } from '@mui/material'
import { FileDownload, NavigateBefore, TextFields } from '@mui/icons-material'
import styled from '@emotion/styled'
import InputField from '../../components/InputFields/TextField'




const MatchingUserList = () => {
    // const slots = JSON.parse(localStorage.getItem('slots'))

    const { id } = useParams()
    const history = useHistory()

    const { state, pathname } = useLocation()
    const [JobPost, setJobPost] = useState([])
    const [IsModalOpen, setIsModalOpen] = useState(false) //filter modal
    const [ModalOpen, setModalOpen] = useState(false)
    const [JobPostModal, setJobPostModal] = useState(false) //for fetch job post from modal
    const [IsConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState([])
    const [ClearFilterData, setClearFilterData] = useState(false)
    const [MatchingArray, setMatchingArray] = useState([])
    // const [JobIdOnly,setJobIdOnly]=useState('')

    const [FilterData, setFilterData] = useState({
        // is_staff: false,
        id: '',

        username: "",
        mobile: "",
        applied_users: [],
        shortlisted_users: [],
        matching_users: [],
        job_id: ''
    })
    // const [title, settitle] = useState('Users')
    const [IsExportModalOpen, setIsExportModalOpen] = useState(false)
    // const [Status, setStatus] = useState([])
    const [JobId, setJobId] = useState('')
    const [JobListArray, setJobListArray] = useState([])

    const [StatusUpdateData, setStatusUpdateData] = useState({ id: "", jobId: "", userId: '', statusCode: '', description: '' })

    useEffect(() => {
        if (state) {
            console.log("state", state.matchingList)
            if (state.jobId) {
                GetJobsList()
                GetUsers(state.matchingList)
                console.log(state.matchingList)

            }

        }
        else {
            console.log("coming to else part")

            GetJobsList()
            setJobPostModal(true)
            setloading(false)
        }
        console.log({ state })
    }, [state])

    useEffect(() => {
        console.log({ id })
    }, [id])



    useEffect(() => {
        let jobpostList = []
        if (JobPost.length) {

            for (let i = 0; i < JobPost.length; i++) {
                jobpostList.push({
                    label: JobPost[i].id + ', ' + JobPost[i].job_name + ' ' + `(${JobPost[i].matching_list.length})`,
                    id: JobPost[i].id,
                    count: JobPost[i].matching_list.length,
                    matchingList: JobPost[i].matching_list,
                    job_name: JobPost[i].job_name
                })

            }

        }
        setJobListArray(jobpostList)

    }, [JobPost.length])

    useEffect(() => {
        if (ClearFilterData) {
            GetUsers()
        }
    }, [ClearFilterData])




    const GetJobsList = () => {
        let path = '/jobpost/'
        let param = 'pagination=0'
        let result = ManageAPIs('get', path, '', param)

        result.then((res) => {
            console.log(res)

            if (res.data) {
                setJobPost(res.data)

            }
        })
            .catch((err) => {
                console.log(err)
            })

    }

    const GetUsers = (matchingList) => {
        // return console.log("match match ", matchingList, matchingList.length)
        if (!matchingList) {
            return ShowFailedMessage('please choose a job post')
        }

        if (!matchingList.length) {
            return ShowFailedMessage('please choose a job post')
        }
        let param = ""
        if (matchingList.length > 0) {
            console.log("if ", matchingList, matchingList.length)

            param += `user_list=[${matchingList}]`
            let path = '/user/listUsers/'

            let result = ManageAPIs('get', path, '', param ? param : '',)

            result.then((res) => {
                console.log("--->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", res)


                if (res.data.results.length >= 1) {
                    console.log("data", res.data.results)
                    setdata(res.data.results)

                    setNext(res.data.next)
                    setPrev(res.data.previous)

                    setloading(false)
                    setJobPostModal(false)
                } else {
                    setdata([])

                    setloading(false)
                    setNext()
                    setPrev()

                }
                // }
            })
                .catch((err) => {
                    console.log(err)
                })
        }
        else if (JobId.matchingList?.length) {
            console.log("else if ", matchingList, matchingList.length)
            param += `user_list=[${JobId.matchingList}]`
            let path = '/user/listUsers/'

            let result = ManageAPIs('get', path, '', param ? param : '',)

            result.then((res) => {
                console.log("--->>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", res)
                // setClearFilterData(false)
                // if (params?.includes('pagination=0')) {
                //     if (res.data) {
                //         setdata(res.data)

                //         setloading(false)

                //     } else {
                //         setdata([])

                //         setloading(false)


                //     }
                // } else {

                if (res.data.results.length >= 1) {
                    console.log("data", res.data.results)
                    setdata(res.data.results)

                    setNext(res.data.next)
                    setPrev(res.data.previous)

                    setloading(false)

                } else {
                    setdata([])

                    setloading(false)
                    setNext()
                    setPrev()

                }
                // }
            })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            console.log("else ", matchingList, matchingList.length)
            setdata([])
        }



    }




    const HandleFilter = (matchingList) => {
        // GetJobRequest(joId)
        if (!matchingList) {
            return ShowFailedMessage('please choose a job post')
        }
        console.log("matching list", matchingList)
        if (matchingList.length) {
            GetUsers(matchingList)
        }
        else {
            setdata([])
            // GetUsers(matchingList)
        }

        setIsModalOpen(false)

    }




    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setdata(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        let path = Next.replace('http:', protocol.replace('//', ''))

        // return console.log({path})
        let result = ManagePagination(path, '')

        result.then(function (res) {
            console.log((res.data));
            if (res.data.results) {
                setdata(res.data.results)
                setNext(res.data.next)
                setPrev(res.data.previous)
                setloading(false)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const ChangeStatus = () => {



        let formdata = new FormData();
        formdata.append('id', StatusUpdateData.id);
        formdata.append('job', StatusUpdateData.jobId);
        formdata.append('status', StatusUpdateData.statusCode);
        formdata.append('user', StatusUpdateData.userId);
        formdata.append('description', StatusUpdateData.description);

        let path = '/jobpost/jobrequest/'
        let result = ''
        result = ManageAPIs('add', path, formdata, '')
        // if (Status <= 1) {
        //     result = ManageAPIs('add', path, formdata, '')
        // } else {
        //     result = ManageAPIs('update', path, formdata, '')
        // }


        result.then((res) => {
            console.log(res)

            // when datas length = null clearing state and open modal for choosing job



            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                setStatusUpdateData({ id: "", jobId: "", userId: '', statusCode: '', description: '' })
                // GetUsers(JobId.matchingList)
                setdata(data.filter(itm => itm.id != res.data.Data.user))

                if (data.length <= 0) {
                    history.replace({ state: '' })
                }

            } else {
                ShowFailedMessage(res.data.Message)

            }
        })
            .catch((err) => {
                console.log(err)
            })
    }



    return (
        <>
            <div class="card ">
                <div class="d-flex justify-content-between card-header pb-0 ">
                    <h5>Matching Users </h5>
                    <div>
                        <button class="btn btn-primary " onClick={() => setIsModalOpen(true)} >Change Job Post</button>

                    </div>
                </div>
                <div className="">
                    {IsModalOpen &&
                        <CustomModal
                            ModalOpen={IsModalOpen}
                            title={'FilterData'}
                            onClose={() => { setIsModalOpen(false) }}
                            PrimaryButtonName={'Submit'}
                            PrimaryButtonFunc={() => HandleFilter(JobId.matchingList)}
                        >
                            <div className="row">

                                <Autocomplete
                                    disablePortal
                                    id="combo-box"
                                    options={JobListArray}
                                    fullWidth
                                    value={JobId}
                                    onChange={(e, value) => setJobId(value)}
                                    size={'small'}
                                    renderInput={(params) => <TextField {...params} label="Choose a job post" placeholder={'Type job id or job name'} />}

                                />


                            </div>
                        </CustomModal>
                    }


                    {IsExportModalOpen &&
                        <CustomModal
                            ModalOpen={IsExportModalOpen}
                            // title={'exporting'}
                            onClose={() => { setIsExportModalOpen(false) }}
                            PrimaryButtonName={'download'}
                            ExportButton={<Download title={'Supervisor'} data={data} isDisabled={loading} />}
                        >
                            <div className="row">
                                {loading ?
                                    <Loader />
                                    :
                                    <div className='d-flex justify-content-center'>
                                        <h6>
                                            Your file is ready to download
                                        </h6>

                                    </div>
                                }
                            </div>
                        </CustomModal>
                    }


                </div>


                <div className="row card-body">

                    <div className="d-flex justify-content-between">
                        <div>
                            {/* <Link to={`/create-supervisor`} class="btn btn-primary "  >Add Supervisor</Link> */}

                            {((FilterData?.applied_users.length || FilterData?.matching_users.length || FilterData?.shortlisted_users.length || FilterData?.id || FilterData?.username || FilterData?.mobile) &&
                                <div className="d-flex align-items-center">

                                    <p className="mb-0">FilterData Applied</p>
                                    <button className="font-primary btn  m-0" onClick={() => { setFilterData({ ...FilterData, shortlisted_users: '', applied_users: '', username: '', id: '', mobile: '', matching_users: [] }); setClearFilterData(true); }}>
                                        <i className="fas fa-times-circle " style={{ marginRight: '.25rem' }}></i>
                                        Clear FilterData
                                    </button>
                                </div>
                            )}
                        </div>


                        <div>
                            <button className='btn btn-primary' disabled={!Prev ? true : false} onClick={HandlePrev}>Prev </button>
                            {/* {` ${currentPage}/${TotalPages}`} */}
                            <button className='btn btn-primary ' style={{ marginLeft: '.25rem' }} disabled={!Next ? true : false} onClick={HandleNext}> Next</button>
                        </div>
                    </div>
                    <div className="table-responsive custom-scrollbar">

                        <div id="basic-3_wrapper" className="dataTables_wrapper">

                            {data.length ?
                                <table className="display dataTable" id="basic-3" role="grid" aria-describedby="basic-3_info">
                                    <thead>
                                        <tr role="row">

                                            <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '75.8875px' }}>
                                                {("Id")} </th>
                                            <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '275.8875px' }}>
                                                {("Name")} </th>
                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Email")}</th>
                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Mobile")}</th>

                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Job designation")}</th>

                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Job")}</th>
                                            <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '300px' }}>
                                                {("Action")}
                                            </th>

                                        </tr>


                                    </thead>



                                    <tbody>
                                        {

                                            data.map((item) => (

                                                <tr role="row" className="odd ">

                                                    <td>{item.id}</td>
                                                    <td>{`${item.first_name && item.first_name}  
                                                ${item.middle_name !== null ? item.middle_name : ''}  
                                                ${item.last_name && item.last_name}`}  </td>
                                                    <td>{item.email}</td>
                                                    <td>{item.google_id == item.mobile ? '' : item?.facebook_id == item.mobile ? '' : item?.linkedin_id == item.mobile ? '' : item.mobile}</td>
                                                    <td>{item.current_designation}</td>
                                                    <td>{JobId ? JobId.job_name : state.jobName}</td>
                                                    <td>
                                                        <div className="d-flex">

                                                            <Button color='primary' marginRight="1rpm" variant='contained' onClick={() => { setStatusUpdateData({ ...StatusUpdateData, id: item.id, jobId: JobId ? JobId.id : state.jobId, userId: item.id, statusCode: 1 }); setModalOpen(true) }}  //onClick={() => setModalOpen(true)}
                                                            >{'shortlist'}</Button>
                                                            <Button color='error' variant='contained' onClick={() => { setStatusUpdateData({ ...StatusUpdateData, id: item.id, jobId: JobId ? JobId?.id : state.jobId, userId: item.id, statusCode: 3 }); setModalOpen(true) }}  //onClick={() => setModalOpen(true)}
                                                            >{'reject'}</Button>
                                                            <ColorButton size='small' variant="contained" color='secondary' onClick={() => history.push({ pathname: `/user-details/${item.id}`, state: { data: item } })} >
                                                                View
                                                            </ColorButton>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>




                                    {ModalOpen &&
                                        <CustomModal PrimaryButtonName={'Submit'} PrimaryButtonFunc={() => { ChangeStatus(); setModalOpen(false) }} ModalOpen={ModalOpen} onClose={() => setModalOpen(false)} >
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label className='col-form-label'>{'Comment'} </label>
                                                </div>
                                                <div className='col-12'>
                                                    <textarea className='form-control' name="" id="" rows="4"
                                                        onChange={(e) => setStatusUpdateData({ ...StatusUpdateData, description: e.target.value })} value={StatusUpdateData?.description}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </CustomModal>
                                    }
                                </table>


                                :
                                <h5>
                                    {loading ? <Loader /> :
                                        <div className="d-flex justify-content-center">
                                            {/* {data.length > } */}

                                        </div>}
                                </h5>
                            }

                            {JobPostModal &&
                                <CustomModal ModalOpen={JobPostModal} onClose={() => setJobPostModal(false)} PrimaryButtonFunc={() => { GetUsers(JobId.matchingList); }} PrimaryButtonName={'Apply'} >
                                    <div className='row'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box"
                                            options={JobListArray}
                                            fullWidth
                                            value={JobId}

                                            onChange={(e, value) => {
                                                setJobId(value)

                                            }
                                            }
                                            size={'small'}
                                            renderInput={(params) => <TextField {...params} label="Choose a job post" placeholder={'Type job id or job name'} />}

                                        />
                                    </div>

                                </CustomModal>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </ >
    )
}

export default MatchingUserList


const ColorButton = styled(Button)(({ }) => ({
    // color: theme.palette.getContrastText('#2c245d'),
    backgroundColor: '#2c245d',
    '&:hover': {
        backgroundColor: '#2c245d',
        color: '#dedede'
    },
}));