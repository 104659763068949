import React, { memo, useEffect, useRef, useState } from 'react'

const MultiSelect = ({ label, arrayList, onSelect, SkillSelected, Search, SearchValue, removeSkill, handleAddSkill, allList }) => {
    const ref = useRef()
    const [isHidden, setisHidden] = useState(true)

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setisHidden(true)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    // console.log(arrayList.find(itm => itm).id)
    console.log({ allList })

    const filteredArray = arrayList.filter(itm => itm.name.toLowerCase().includes(SearchValue.toLowerCase()))
    return (
        <div className='row'>
            <div className="col-3 d-flex align-items-center">
                <label className={'col-form-label'}>{label}</label>
            </div>

            <div className="col-9" ref={ref}>


                <div className={`form-control d-flex px-1`}>
                    <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                        {SkillSelected.map(item => (
                            <div>
                                <button type='button' className='btn btn-sm btn-outline-primary px-2 py-1 mx-2 w-auto my-1'>{allList.find(itm => itm.id == item)?.name}
                                    <i class="fas fa-times" style={{ marginLeft: '.5rem' }} onClick={() => removeSkill(item)}></i></button>
                            </div>
                        ))}
                        <input type="text" className=' form-control' style={{ border: 'none', width: 'max-content' }} onChange={Search} value={SearchValue} onClick={() => setisHidden(false)} />
                    </div>


                </div>
                <div className={`multi-select-dropdown ${isHidden ? 'd-none' : 'd-block'}`} >
                    <ul>
                        {filteredArray.map(item => (
                            <>
                                <li style={{ borderBottom: '1px solid #8f8f8f', cursor: 'pointer' }} onClick={() => onSelect(item.id)} className={SkillSelected.includes(item.id) ? 'selected_skill p-1 ' : 'p-1 '}>{item.name}</li>
                            </>
                        ))}
                        {SearchValue.length >= 1 &&
                            <li>
                                <button type='button' className='btn btn-secondary py-1 my-1 ' onClick={handleAddSkill}>Add New Skill</button>
                            </li>
                        }
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default memo(MultiSelect)