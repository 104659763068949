import React from 'react'

const SelectField = (props) => {
  // console.log(props.options[1].value)
  return (

    <div className='row'>
      <div className='col-3 ' >
        <label className='col-form-label'>{props.label} {props.isRequired ? '*' : ''}</label>
      </div>

      <div className='col-9 '>
        <select className='form-control' name={props.name} disabled={props.disabled} onChange={props.onchange} value={props.value} multiple={props.isMultiple}>
          {props.placeholder &&
            <option value="" selected >{props.placeholder}</option>
          }
          {props.options?.map(item => (
            <option value={item.id}>{item.value.replaceAll('_',' ')}</option>
          ))}
        </select>

      </div>
    </div>

  )
}

export default SelectField