import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Context } from './components/context/context';
import { useEffect, useState } from 'react';
import Login from './pages/Login/login';
// import Slot from './pages/Supervisor/users';
import Settings from './pages/Settings/Settings';
import Toast from './components/Toast/toast';
import 'react-toastify/dist/ReactToastify.css';
import i18n from "./i18n"

import Application from './pages/JobPost/jobDetails';

// import UserCoinHistory from './pages/UserCoinHistory/UserCoinHistory';
import Export from './pages/Export/export';
import Applications from './pages/JobPost/Jobs';
// import Agents from './pages/agents/agents';
import Dashboard from './pages/Dashboard/dashboard';
import Layout from './components/Layout/Layout';
import Users from './pages/users/Users';
import Admins from './pages/Admins/Admins';
import AdminDetails from './pages/Admins/AdminDetails';
import UserDetails from './pages/users/UserDetails';

import AppliedUserList from './pages/StatusFilter/AppliedUserList';
import ShortlistedUserList from './pages/StatusFilter/ShortlistedUserList';
import SelectedUserList from './pages/StatusFilter/SelectedUserList';
import MatchingUserList from './pages/StatusFilter/MatchingUserList';
import RejectedUserList from './pages/StatusFilter/RejectedUserList';
import AdminHandledUsers from './pages/users/AdminHandledUsers';
import EmployeeDetails from './pages/EmployeeDetails/EmployeeDetails';
import EmployeeDetailsTemplate from './pages/EmployeeDetails/EmployeeDetailsTemplate';

function App() {



  const [isSidebarOpen, setisSidebarOpen] = useState(true)
  const [IsSuperAdmin, setIsSuperAdmin] = useState(false)
  const [IsSuperVisor, setIsSuperVisor] = useState(false)
  // const [isLabStaff, setIsLabStaff] = useState(localStorage.getItem("ctm_token") && JSON.parse(localStorage.getItem("ctm_token"))?.is_lab_staff)
  const [Alert, setAlert] = useState({
    active: false,
    message: '',
    variant: '',
    icon: '',
    title: '',
    duration: null
  })

  const [token, setToken] = useState(localStorage.getItem("jobportal_token"))
  const [appId, setAppId] = useState()
  const [restApiKey, setRestApiKey] = useState()
  const [user, setuser] = useState('')
  const [forWho, setforWho] = useState('')
  const [JobPost, setJobPost] = useState([])


  useEffect(() => {
    setuser(localStorage.getItem('user'))
    setforWho(localStorage.getItem('supervisorFor'))
    setToken(localStorage.getItem('jobportal_token'))
    
    if (localStorage.getItem('jobportal_data')) {
      let userdata = JSON.parse(localStorage.getItem('jobportal_data'))
      setIsSuperAdmin(userdata?.is_superuser)
    }
  }, [])


  // alert(user)

  const [activePage, setActivePage] = useState(window.location.pathname)

  return (
    <Context.Provider
      value={{
        isSidebarOpen, setisSidebarOpen,
        Alert, setAlert,
        token, setToken,

        IsSuperAdmin, setIsSuperAdmin,
        // IsSuperVisor, setIsSuperVisor,
        user, setuser,
        activePage, setActivePage,
        forWho, setforWho,
        JobPost, setJobPost
      }}
    >
      <Router>
        <Toast />
        {/* {token ? */}
        <Switch>
          <Route exact path="/"><Login /></Route>

          <Layout >

            <Route path="/job-post" exact component={Applications}></Route>
            <Route path="/job-post/:id" exact component={Application}></Route>
            <Route path="/add-job-post" component={Application}></Route>

            <Route path="/users" component={Users}></Route>
            <Route path="/admin/shortlisted-users" component={AdminHandledUsers}></Route>
            <Route path="/admin/selected-users" component={AdminHandledUsers}></Route>
            <Route path="/admin/rejected-users" component={AdminHandledUsers}></Route>
            <Route path="/matching-users" component={MatchingUserList}></Route>
            <Route path="/matching-list/:id" component={MatchingUserList}></Route>
            <Route path="/shortlisted-users" component={ShortlistedUserList}></Route>
            <Route path="/selected-users" component={SelectedUserList}></Route>
            <Route path="/applied-users" component={AppliedUserList}></Route>
            <Route path="/rejected-users" component={RejectedUserList}></Route>
            <Route path="/user-details/:id" component={UserDetails}></Route>
            <Route path="/create-users" component={UserDetails}></Route>
            <Route path="/profile" component={UserDetails}></Route>
            <Route path="/admins" component={Admins}></Route>
            <Route path="/create-admin" component={AdminDetails}></Route>
            <Route path="/admin-details/:id" component={AdminDetails}></Route>
            <Route path="/aliens" component={EmployeeDetails}></Route>
            <Route path="/alien-detail/:id" component={EmployeeDetailsTemplate}></Route>
            <Route path="/create-alien" component={EmployeeDetailsTemplate}></Route>


            <>
              <Route path="/dashboard" component={Dashboard}></Route>

              <Route path="/settings" component={Settings}></Route>

            </>



            {/* <Route path="/user-coin-history/:id" component={UserCoinHistory}></Route> */}



            {/* <Route path="/agents" component={Agents}></Route> */}




          </Layout>

          <Route><Error404 /></Route>
        </Switch>
        {/* :
          <Switch>
            <Route exact path="/"><Login /></Route>
            <Redirect to="/" />
          </Switch> */}
        {/* } */}
      </Router>
    </Context.Provider>
  );
}

export default App;



function Error404() {
  return (
    <div
      style={{
        color: "black",
        height: "100vh",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          color: "white",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            justifyContent: "center",
            backgroundRepeat: "no-repeat",
            width: "300px",
            height: "100px",
            display: "flex",
            color: "black",
          }}
        >
          {/* backgroundImage:`url(${Image404})`, */}
          {/* <img src={Image404} /> */}
        </span>
        <b style={{ color: "black" }}>Page Not Found</b>

        <span style={{ color: "black" }}>
          The page you are looking for was not found.
        </span>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
          }}
        >
          Please go back to previous page or{" "}
          <a href="/" style={{ color: "green" }}>
            {" "}
            &nbsp;Go to Home
          </a>
        </span>
      </div>
    </div>
  );
}
