import React from 'react'
import { Link } from 'react-router-dom'

const DashboardButton = (props) => {
    return (
        <div>
            <Link to={props.pagelink} className='btn btn-primary w-100' style={{ borderRadius: '14px' }}>
                <span className='d-flex'>
                    <span className='d-flex align-items-center ' style={{ marginRight: '1rem', width:'2.5rem ' }}>
                        {props.icon}
                    </span>
                    <span style={{ textAlign: 'left' }}>
                        <h6>{props.title}</h6>
                        <h4>{props.count}</h4>


                    </span>
                </span>
            </Link>
        </div>
    )
}

export default DashboardButton