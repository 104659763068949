import { GET_API } from "../../Functionalities/APIS"

export const ManageDashboard = async (keyword, data, params) => {
    let path = '/admins/dashboard/'

    if (keyword.includes('get')) {

        let result = GET_API('', params, path, data)
        return await result.then((res) => {
            if (res) {
                return res
            } else {
                return 0
            }
        }
        )
    }

}
