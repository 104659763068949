import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { Slide, toast } from "react-toastify";
import BackButtonComponent from "../../components/backButtonComponent/backButtonComponent";
import Layout from "../../components/Layout/Layout";
import {
  ShowSuccessMessage,
  ShowFailedMessage,
  restrictWhiteSpace,
  PreventNonNumeric,
  addDomainInImage,
  validateEmail,
} from "../../Functionalities/functions";
import { useTranslation } from "react-i18next";
import {
  lengthValidation,
  userValidation,
} from "../../Functionalities/Validations";
import { Link } from "react-router-dom";
import { ConvertException } from "../../Functionalities/CTMValidation";
import TextField from "../../components/InputFields/TextField";
import UseForm from "../../components/Hooks/UseForm";
import SelectField from "../../components/InputFields/SelectField";
import { ManageAPIs } from "../../Functionalities/Logic";
import InputRadio from "../../components/InputFields/InputRadio";
import { Button } from "@mui/material";
import { Context } from "../../components/context/context";

let path = "/user/adminadd/";

const AdminDetails = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { IsSuperAdmin } = useContext(Context);

  const [isButtonActive, setisButtonActive] = useState(true);
  const [BtnLoading, setBtnLoading] = useState(false);

  const [InitialValue, setInitialValue] = useState({
    id: "",
    username: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    gender: "",
    is_active: false,
  });
  const [IsEditable, setIsEditable] = useState(false);
  const [ClearState, setClearState] = useState(false);
  const [data, setData] = UseForm(InitialValue, ClearState, setClearState);
  const [changePassword, setchangePassword] = useState(false);
  const [AdminHandledUsersList, setAdminHandledUsersList] = useState({ rejected_user: [], selected_user: [], shortlisted_user: [] })
  useEffect(() => {
    if (id) {
      GetUsers();
      GetAdminHandledUsers()
    }
  }, [id]);

  useEffect(() => {
    setIsEditable(!IsSuperAdmin);
  }, [IsSuperAdmin]);

  const GetUsers = () => {
    path = "/user/adminadd/";
    let param = `id=${id}`;
    let result = ManageAPIs("get", path, "", param);

    result
      .then((res) => {
        console.log("user data", res);
        if (res.data.results) {
          setInitialValue(res.data.results[0]);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ data });
    let validation = userValidation(data, [
      "username",
      "first_name",
      "last_name",
      "email",
      "mobile",
      "password",
      "confirm_password",
      "gender",
    ]);

    if (!validation) {
      return;
    }
    // if (data.mobile?.length != 10) {
    //     ShowFailedMessage(`Invalid Mobile Number`)
    //     return
    // }
    // if (data.mobile?.length <= 9) {
    //     ShowFailedMessage(`Mobile Must be at least 10 number`)
    //     return
    // }

    if (!validateEmail(data.email)) {
      return ShowFailedMessage("Invalid email");
    }

    if (data.password !== data.confirm_password) {
      ShowFailedMessage(`password does not match`);
      return;
    }

    var formdata = new FormData();

    id && formdata.append("id", data.id);
    formdata.append("username", data.username);
    formdata.append("email", data.email);
    data.password && formdata.append("password", data.password);
    formdata.append("first_name", data.first_name);
    formdata.append("middle_name", data.middle_name ? data.middle_name : "");
    formdata.append("last_name", data.last_name);
    formdata.append("gender", data.gender);
    formdata.append("mobile", data.mobile);
    id && formdata.append("is_active", data.is_active);

    // return
    let result = "";
    if (id) {
      result = ManageAPIs("update", path, formdata, "");
    } else {
      result = ManageAPIs("add", path, formdata, "");
    }

    // let result = ManageUsers('add', data, '', path)

    result
      .then((res) => {
        setisButtonActive(false);
        setBtnLoading(true);
        console.log(res);
        if (res.data.Status) {
          ShowSuccessMessage(res.data.Message);
          setClearState(true);
          setisButtonActive(true);
          setBtnLoading(false);
          if (res.data.Message.includes("updated")) {
            let response = res.data.Data;

            setInitialValue({
              ...InitialValue,
              id: response.id,
              username: response.username,
              first_name: response.first_name,
              middle_name: response.middle_name ? response.middle_name : "",
              last_name: response.last_name,
              mobile: response.mobile,
              email: response.email,
              gender: response.gender,
              is_active: response.is_active,
            });
          } else {
            setInitialValue({
              id: "",
              username: "",
              first_name: "",
              middle_name: "",
              last_name: "",
              mobile: "",
              email: "",
              password: "",
              confirm_password: "",
              gender: "",
              is_active: false,
            })
          }
        } else if (res.data.Message.includes("Excepction occured")) {
          console.log(res.data.Data.referral_code);
          console.log(res.data.Data);
          ConvertException(res.data.Data);
          setisButtonActive(true);
          setBtnLoading(false);
        } else {
          ShowFailedMessage(res.data.Message);
          setisButtonActive(true);
          setBtnLoading(false);
        }
      })

      .catch((err) => {
        console.log(err);
        setisButtonActive(true);
        setBtnLoading(false);
      });
  };

  const genderOptions = [
    { id: 0, value: "MALE" },
    { id: 1, value: "FEMALE" },
    { id: 2, value: "OTHER" },
  ];


  const GetAdminHandledUsers = () => {
    let path = '/jobpost/handledUsers/'
    let param = `admin=${id}`

    let result = ManageAPIs('get', path, '', param)

    result.then((res) => {
      console.log(res)
      if (res.data.data) {
        setAdminHandledUsersList(res.data.data)
      }
    })

      .catch((err) => {
        console.log(err)
      })

  }
  console.log({ AdminHandledUsersList })

  return (
    <>
      <div className="card">
        <BackButtonComponent />

        {/* {noData ?
                <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                    <h3>{t("No Data Found")}</h3> 
                </div> : */}
        <>
          <div className="card-header d-flex justify-content-between pb-0">
            <h5>
              {window.location.pathname == `/admin-details/${id}`
                ? "admin Details"
                : "Create admin"}
            </h5>
            <div>
              {/* <button type='button' className='btn btn-success mx-2'>Add Coins</button>
                            <button type='button' className='btn btn-dark mx-2'>Reduce Coins</button> */}
              {window.location.pathname == `/supervisor/${id}` && (
                <button
                  className="btn btn-primary"
                  onClick={(e) => setIsEditable(!IsEditable)}
                >
                  {IsEditable ? "Cancel" : "Edit"}
                </button>
              )}
            </div>
          </div>

          <form className="form theme-form">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="mb-3 row">
                    <TextField
                      isRequired={true}
                      label={"Username"}
                      type={"text"}
                      placeholder={"Enter username"}
                      onchange={setData}
                      name={"username"}
                      value={data.username}
                      disabled={IsEditable}
                    />
                  </div>
                  <div className="mb-3 row">
                    <TextField
                      isRequired={true}
                      label={"first name"}
                      type={"text"}
                      placeholder={"Enter first name"}
                      onchange={setData}
                      name={"first_name"}
                      value={data.first_name}
                      disabled={IsEditable}
                    />
                  </div>
                  <div className="mb-3 row">
                    <TextField
                      isRequired={false}
                      label={"Middle name"}
                      type={"text"}
                      placeholder={"Enter middle name"}
                      onchange={setData}
                      name={"middle_name"}
                      value={data.middle_name}
                      disabled={IsEditable}
                    />
                  </div>
                  <div className="mb-3 row">
                    <TextField
                      isRequired={true}
                      label={"Last Name"}
                      type={"text"}
                      placeholder={"Enter last name"}
                      onchange={setData}
                      name={"last_name"}
                      value={data.last_name}
                      disabled={IsEditable}
                    />
                  </div>
                  <div className="mb-3 row">
                    <TextField
                      isRequired={true}
                      label={"Mobile"}
                      type={"number"}
                      onKeyDown={PreventNonNumeric}
                      placeholder={"Enter mobile"}
                      onchange={setData}
                      name={"mobile"}
                      value={data.mobile}
                      disabled={IsEditable}
                    />
                  </div>
                  <div className="mb-3 row">
                    <TextField
                      isRequired={true}
                      label={"Email"}
                      type={"text"}
                      placeholder={"Enter Mail Id"}
                      onchange={setData}
                      name={"email"}
                      value={data.email}
                      disabled={IsEditable}
                    />
                  </div>

                  <div className="mb-3 row">
                    <SelectField
                      isRequired={true}
                      label={"Gender"}
                      options={genderOptions}
                      placeholder={"Choose gender"}
                      onchange={setData}
                      name={"gender"}
                      value={data.gender}
                      disabled={IsEditable}
                    />
                  </div>

                  {id ?
                    <>
                      <InputRadio
                        label={"Is Active"}
                        value={data.is_active}
                        onchange={(e) =>
                          setInitialValue({
                            ...InitialValue,
                            is_active: e.target.checked,
                          })
                        }
                        disabled={IsEditable}
                      />

                      <div className="mb-3 row">
                        {IsSuperAdmin && (
                          <div>
                            <Button
                              size="small"
                              onClick={() => setchangePassword(!changePassword)}
                            >
                              {changePassword ? "Cancel" : "change password"}
                            </Button>
                          </div>
                        )}
                      </div>

                      {changePassword &&
                        <>
                          <div className="mb-3 row">
                            <TextField
                              isRequired={true}
                              label={"Password"}
                              type={"password"}
                              placeholder={"Enter password"}
                              onchange={setData}
                              name={"password"}
                              value={data.password}
                              disabled={IsEditable}
                            />
                          </div>
                          <div className="mb-3 row">
                            <TextField
                              isRequired={true}
                              label={"Confirm password"}
                              type={"password"}
                              placeholder={"Enter confirm password"}
                              onchange={setData}
                              name={"confirm_password"}
                              value={data.confirm_password}
                              disabled={IsEditable}
                            />
                          </div>
                        </>
                      }




                    </>
                    :
                    <>
                      <div className="mb-3 row">
                        <TextField
                          isRequired={true}
                          label={"Password"}
                          type={"password"}
                          placeholder={"Enter password"}
                          onchange={setData}
                          name={"password"}
                          value={data.password}
                          disabled={IsEditable}
                        />
                      </div>
                      <div className="mb-3 row">
                        <TextField
                          isRequired={true}
                          label={"Confirm password"}
                          type={"password"}
                          placeholder={"Enter confirm password"}
                          onchange={setData}
                          name={"confirm_password"}
                          value={data.confirm_password}
                          disabled={IsEditable}
                        />
                      </div>
                    </>

                  }

                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-between">

              <div className="col-sm-9  d-flex ">
                {IsSuperAdmin &&
                  <>

                    <button disabled={AdminHandledUsersList.shortlisted_user.length == 0} className={'btn p-0'}>
                      <Link to={{ pathname: '/admin/shortlisted-users', state: { userData: AdminHandledUsersList.shortlisted_user } }} className="btn btn-outline-primary ">Shortlisted Users ({AdminHandledUsersList.shortlisted_user.length})</Link>
                    </button>
                    <button disabled={AdminHandledUsersList.selected_user.length == 0} className={'btn p-0'}>
                      <Link to={{ pathname: '/admin/selected-users', state: { userData: AdminHandledUsersList.selected_user } }} className="btn btn-outline-success mx-2">Selected Users ({AdminHandledUsersList.selected_user.length})</Link>
                    </button>

                    <button disabled={AdminHandledUsersList.rejected_user.length == 0} className={'btn p-0'}>
                      <Link to={{ pathname: '/admin/rejected-users', state: { userData: AdminHandledUsersList.rejected_user } }} className="btn btn-outline-danger ">Rejected Users ({AdminHandledUsersList.rejected_user.length})</Link>
                    </button>
                  </>
                }
              </div>

              <div className="col-sm-3 text-end">
                {IsSuperAdmin && (
                  <button
                    className="btn btn-success mr-3"
                    type="button"
                    disabled={!isButtonActive}
                    onClick={handleSubmit}
                  >
                    {BtnLoading && <i class="fas fa-circle-notch fa-spin"> </i>}{" "}
                    {id ? "Update" : "Submit"}
                  </button>
                )}
                {/* <button className="btn btn-light" defaultValue="Cancel">{t("Reset")}</button> */}
              </div>
            </div>
          </form>
        </>
        {/* } */}
      </div>
    </>
  );
};

export default AdminDetails;
