import React from 'react'
import { useTranslation } from 'react-i18next'

const BackButtonComponent = () => {
    const {t} = useTranslation()
    return (

        <div className="card-body">
            <button type="button" className="btn btn-primary-light " onClick={() => window.history.back()}>
                {t("Back")}
            </button>
        </div>

    )
}

export default BackButtonComponent
