import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Slide, toast } from 'react-toastify'
import BackButtonComponent from '../../components/backButtonComponent/backButtonComponent'
import Layout from '../../components/Layout/Layout'
import { addDomainInImage, formatDate, PreventNonNumeric, restrictWhiteSpace, sendNotification, ShowFailedMessage, ShowSuccessMessage, validateEmail } from '../../Functionalities/functions'
import { useTranslation } from 'react-i18next'
import './style.css'
// import MultiImagesPreview from './MultiImagesPreview'
import { ManageApplications } from './ManageApplications'
import CustomModal from '../../components/Modal/CustomModal'
import axios from 'axios'
import { url } from '../../Functionalities/config'
import { Link } from "react-router-dom"
import { border } from '@mui/system'
import InputTextField from '../../components/InputFields/TextField'
import SelectField from '../../components/InputFields/SelectField'
import UseForm from '../../components/Hooks/UseForm'
import CommentSection from '../../components/CommentSection/CommentSection'
import { ManageUniversity } from '../university/UniversityLogic'
import { ManageCourse } from '../university/CourseLogic'
import ConfirmationModal from '../../components/Modal/confirmationModal'
import { Context } from '../../components/context/context'
import { saveAs } from 'file-saver';
import { ManageUsers } from '../users/UserLogic'
import MultipleSelect from '../../components/InputFields/MultiSelect'
import { ManageAPIs } from '../../Functionalities/Logic'
import MultiSelect from '../../components/MultiSelect/MultiSelect'
import { userValidation } from '../../Functionalities/Validations'
import InputRadio from '../../components/InputFields/InputRadio'
import { Autocomplete, TextField } from '@mui/material'
import { currencies } from 'currencies.json'
import RichText from '../../components/RichText/RichText'




const Application = () => {

    const { id } = useParams()
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    const { token } = useContext(Context)
    const [ClearState, setClearState] = useState(false)
    const [BtnLoading, setBtnLoading] = useState(false)
    const [noData, setNoData] = useState(false)
    const [isButtonActive, setisButtonActive] = useState(true)
    const [IsDisable, setIsDisable] = useState(false)
    const [Files, setFiles] = useState([])
    const [playerId, setplayerId] = useState('')
    const [isModalOpen, setisModalOpen] = useState(false)
    const [InitialValue, setInitialValue] = useState({
        id: '',
        job_name: '',
        description: '',
        requirements: '',
        pay_min_amount: '',
        pay_max_amount: '',
        skills: [],
        min_experience: '',
        max_experience: '',
        is_active: '',
        avatar: '',
        prev_avatar: "",
        location: '',
        education_requirements: '',
        education_type: '',
        job_type: '',
        is_active: true,
        responsibility: ''

    })

    const [data, setdata] = UseForm(InitialValue, ClearState, setClearState,)
    const [IsModalOpen, setIsModalOpen] = useState(false)
    const [SelectedSkills, setSelectedSkills] = useState([])
    const [searchSkills, setsearchSkills] = useState('')
    const [allSkills, setallSkills] = useState([])
    const [Skills, setSkills] = useState([])
    const [currency, setcurrency] = useState('')

    useEffect(() => {
        if (id) {
            GetApplications()
            setIsDisable(true)
        }

    }, [id])

    useEffect(() => {
        // console.log('played id--------------',location.state?.player_id)
        location.state?.player_id && setplayerId(location.state?.player_id); history.replace()
    }, [location.state])

    useEffect(() => {
        GetSkills()
    }, [])

    useEffect(() => {
        if (SelectedSkills) {
            GetSkills(`remove_list=${JSON.stringify(SelectedSkills)}`)
            // GetSkills(JSON.stringify(SelectedSkills))
        }
    }, [SelectedSkills])



    const educationList = [
        { value: 'UG', id: 0 },
        { value: 'PG', id: 1 },
        { value: 'DIPLOMA', id: 2 },
        { value: 'PLUS_TWO', id: 3 },
        { value: 'SSLC', id: 4 },
    ]


    const GetSkills = (param) => {
        let path = '/skill/addskill/'
        let params = ''
        if (param) {
            params = param + '&pagination=0'
        } else {
            params = '&pagination=0'
        }


        let result = ManageAPIs('get', path, '', params)
        result.then((res) => {
            console.log(res)
            if (res.data) {
                if (param) {
                    console.log('removed list', res.data)
                    setSkills(res.data)
                } else {
                    setallSkills(res.data)
                }

            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const AddSkill = () => {
        if (allSkills.find(itm => itm.name.toLowerCase() == searchSkills.toLowerCase())) {
            ShowFailedMessage('alreday exist')
            return
        } else {

            let path = '/skill/addskill/'

            let formdata = new FormData();
            formdata.append('name', searchSkills);

            let result = ManageAPIs('add', path, formdata, '')

            result.then((res) => {
                console.log(res)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    setsearchSkills('')
                    GetSkills()
                    setSkills([...Skills, res.data.Data])

                } else {
                    ShowFailedMessage(res.data.Message)

                }
            })

                .catch((err) => {
                    console.log(err)
                })
        }
    }



    const GetApplications = () => {

        let param = `id=${id}`
        let path = '/jobpost/'

        let result = ManageAPIs('get', path, '', param)

        result.then((res) => {
            console.log('application data------------->', res)
            if (res.data.results) {
                const result = res.data?.results[0]

                setInitialValue({
                    ...InitialValue,
                    id: result.id,
                    job_name: result.job_name,
                    description: result.description,
                    requirements: result.requirements,
                    pay_min_amount: result.pay_min_amount,
                    pay_max_amount: result.pay_max_amount,
                    skills: result.skills,
                    min_experience: result.min_experience,
                    max_experience: result.max_experience,
                    is_active: result.is_active,
                    avatar: result.avatar,
                    location: result.location,
                    responsibility: result.responsibility,
                    job_type: EmployeeType.find(item => item.value == result.job_type).id,
                    education_requirements: educationList.find(item => item.value == result.education_requirements).id,
                    education_type: result.education_type
                })
                setcurrency(currencyList.find(itm => itm.id == result.currency))
                // console.log(educationList.find(item => item.value == result.education_requirements)?.id)
                // console.log(EmployeeType.find(item => item.value == result.job_type)?.id)
                let arr = []
                for (let i = 0; i < result.skills.length; i++) {
                    arr.push(result.skills[i].id)
                }
                GetSkills(`remove_list=${JSON.stringify(arr)}`)


                let skillArray = []
                for (let i = 0; i < result.skills.length; i++) {
                    skillArray.push(result.skills[i].id)
                }
                setSelectedSkills(skillArray)




            } else {
                setNoData(true)
            }
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setisButtonActive(false)
        setIsModalOpen(false)
        // return

        let validatingList = ['job_name', 'description', 'requirements', 'responsibility', 'pay_min_amount', 'pay_max_amount', 'skills', 'min_experience', 'max_experience', 'location', 'education_requirements', 'job_type', 'currency']

        let validation = userValidation(data, validatingList)

        if (!validation) {
            return
        }

        if (window.location.pathname == '/add-job-post') {
            let formdata = new FormData()

            formdata.append('job_name', data.job_name);
            formdata.append('pay_min_amount', data.pay_min_amount);
            formdata.append('pay_max_amount', data.pay_max_amount);
            SelectedSkills && formdata.append('skills', JSON.stringify(SelectedSkills));
            formdata.append('min_experience', data.min_experience);
            formdata.append('description', data.description);
            formdata.append('max_experience', data.max_experience);
            formdata.append('avatar', data.prev_avatar);
            formdata.append('requirements', data.requirements);
            formdata.append('education_requirements', data.education_requirements);
            formdata.append('education_type', data.education_type);
            formdata.append('location', data.location);
            formdata.append('currency', currency.id);
            formdata.append('responsibility', data.responsibility);


            let path = '/jobpost/'

            let result = ManageAPIs('add', path, formdata, '', )

            result.then((res) => {
                console.log('add res', res)
                setisButtonActive(true)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    console.log(res.data.Message)
                    setInitialValue({
                        ...InitialValue,
                        id: '',
                        job_name: '',
                        description: '',
                        requirements: '',
                        pay_min_amount: '',
                        pay_max_amount: '',
                        skills: [],
                        min_experience: '',
                        max_experience: '',
                        is_active: '',
                        avatar: '',
                        prev_avatar: "",
                        location: '',
                        education_requirements: '',
                        education_type: '',
                        job_type: '',
                        responsibility: '',

                    })
                    setSelectedSkills([])
                    setcurrency('')
                } else {
                    ShowFailedMessage(res.data.Message)
                    console.log(res.data.Message)
                }
            })


                .catch((err) => {
                    console.log(err)
                    setisButtonActive(true)
                })

        } else {

            let formdata = new FormData()

            formdata.append('id', data.id);
            formdata.append('job_name', data.job_name);
            formdata.append('pay_min_amount', data.pay_min_amount);
            formdata.append('pay_max_amount', data.pay_max_amount);
            SelectedSkills && formdata.append('skills', JSON.stringify(SelectedSkills));
            formdata.append('min_experience', data.min_experience);
            formdata.append('description', data.description);
            formdata.append('max_experience', data.max_experience);
            formdata.append('responsibility', data.responsibility);

            if (typeof (data.prev_avatar) == 'object') {
                formdata.append('avatar', data.prev_avatar);
            }

            formdata.append('requirements', data.requirements);
            formdata.append('education_requirements', data.education_requirements);
            formdata.append('education_type', data.education_type);
            formdata.append('location', data.location);
            formdata.append('is_active', data.is_active);
            formdata.append('currency', currency.id);


            let path = '/jobpost/'

            let result = ManageAPIs('update', path, formdata, '', )

            result.then((res) => {
                console.log('update response', res)
                setisButtonActive(true)
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message)
                    console.log(res.data.Message)

                    const result = res.data.Data

                    setInitialValue({
                        ...InitialValue,
                        id: result.id,
                        job_name: result.job_name,
                        description: result.description,
                        requirements: result.requirements,
                        pay_min_amount: result.pay_min_amount,
                        pay_max_amount: result.pay_max_amount,
                        skills: result.skills,
                        min_experience: result.min_experience,
                        max_experience: result.max_experience,
                        is_active: result.is_active,
                        avatar: result.avatar,
                        location: result.location,
                        responsibility: result.responsibility,
                        job_type: EmployeeType.find(item => item.value == result.job_type).id,
                        education_requirements: educationList.find(item => item.value == result.education_requirements).id,
                        education_type: result.education_type
                    })
                    setcurrency(currencyList.find(itm => itm.id == result.currency))

                    // GetApplications()
                    // setplayerId(res.data.Data.user.player_id)
                    let arr = []
                    for (let i = 0; i < result.skills.length; i++) {
                        arr.push(result.skills[i].id)
                    }

                    setSelectedSkills(arr)
                    GetSkills(`remove_list=${JSON.stringify(arr)}`)

                } else {
                    ShowFailedMessage(res.data.Message)
                    console.log(res.data.Message)
                }
            })


                .catch((err) => {
                    console.log(err)
                    setisButtonActive(true)
                })
        }


    }


    const DeleteApplication = () => {
        // return console.log(JSON.stringify([id]))
        let path = '/jobpost/'
        let result = ManageAPIs('delete', path, '', `ids=${JSON.stringify([id])}`, )
        result.then((res) => {
            console.log('application delete res-----------', res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                history.push('/job-post')
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleClick = (id) => {
        if (!SelectedSkills.includes(id)) {
            setSelectedSkills([...SelectedSkills, id])
        } else {
            setSelectedSkills(SelectedSkills.filter(itm => itm !== id))
        }
    }

    const HandleRemove = (id) => {
        setSelectedSkills(SelectedSkills.filter(itm => itm !== id))
    }

    // useEffect(() => {
    //     if (data.skills) {
    //         let arr = []
    //         for (let i = 0; i < data.skills.length; i++) {
    //             arr.push(data.skills[i].id)
    //         }
    //         setSelectedSkills(arr)
    //     }
    //     console.log({ SelectedSkills })
    // }, [data])



    console.log({ SelectedSkills })
    console.log({ data })

    let currencyList = []


    for (let i = 0; i < currencies.length; i++) {
        currencyList.push({ label: currencies[i].name, id: currencies[i].code })
    }



    console.log('currency -----', data.currency)

    return (

        <>

            <div className="card">
                <div className="row">
                    <div className="col-6">
                        {/* <BackButtonComponent /> */}
                    </div>
                    {(id && !noData) &&
                        <div className="col-6 card-body d-flex justify-content-end">
                            <button className='btn btn-danger' onClick={() => setisModalOpen(true)}>Delete post</button>
                        </div>
                    }
                </div>

                {isModalOpen &&
                    <ConfirmationModal title={''} message={('Are you sure you want to delete this job post?')} onSubmit={(e) => DeleteApplication(e)} onClose={() => setisModalOpen(false)} ModalOpen={isModalOpen} />
                }




                {noData ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                        <h3>{t("No Data Found")}</h3>
                    </div>

                    :

                    <>



                        <div className="card-header pb-0 d-flex justify-content-between">
                            <h5>{id ? 'Job Details' : 'Create Job Post'}</h5>
                        </div>



                        <form className="form theme-form">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col">


                                        <div className="row">

                                            <div className="col-sm-3" />
                                            <div className="col-sm-9 d-flex align-items-start" style={{ flexDirection: 'column', color: 'gray' }}>
                                                <img className src={(data.prev_avatar ? URL.createObjectURL(data.prev_avatar) : addDomainInImage(data.avatar))} style={{ borderRadius: '7px', height: '100px', }} />
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <InputTextField label={' Icon'} type={'file'} buttonName={'Upload File'} id={'docUpload'} isRequired={false} onchange={(e) => {
                                                if (e.target.files[0]) {
                                                    setInitialValue({ ...InitialValue, prev_avatar: e.target.files[0] })
                                                }

                                            }} isDisabled={false} acceptFile={"image/*,application/*"} />
                                        </div>


                                        <div className="mb-3 row">
                                            <InputTextField label={'Job Name'} type={'text'} onchange={(e) => setInitialValue({ ...InitialValue, job_name: e.target.value })} placeholder={'job name'} name={'job_name'} isRequired={true} value={data.job_name} isDisabled={false} />
                                        </div>

                                        <div className="mb-3 row">
                                            <RichText label={'Description'} onChange={value => setInitialValue({ ...InitialValue, description: value })} postData={data.description == null ? '' : data.description} isRequired={true} />
                                        </div>
                                        <div className="mb-3 row">
                                            <RichText label={'Requirements'} onChange={value => setInitialValue({ ...InitialValue, requirements: value })} postData={data.requirements == null ? '' : data.requirements} isRequired={true} />
                                        </div>
                                        <div className="mb-3 row">
                                            <RichText label={'Responsibility'} onChange={value => setInitialValue({ ...InitialValue, responsibility: value })} postData={data.responsibility == null ? '' : data.responsibility} isRequired={true} />
                                        </div>



                                        <div className="mb-3 row">
                                            <InputTextField label={'Min Salary'} type={'number'} onchange={(e) => setInitialValue({ ...InitialValue, pay_min_amount: e.target.value })} placeholder={'Min Salary'} name={'pay_min_amount'} isRequired={true} value={data.pay_min_amount} isDisabled={false} />
                                        </div>

                                        <div className="mb-3 row">
                                            <InputTextField label={'Max Salary'} type={'number'} onchange={(e) => setInitialValue({ ...InitialValue, pay_max_amount: e.target.value })} placeholder={'Max Salary'} name={'pay_max_amount'} isRequired={true} value={data.pay_max_amount} isDisabled={false} />
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="row">

                                                <div className='col-3'></div>
                                                <div className='col-9'>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={currencyList}
                                                        fullWidth
                                                        value={currency}
                                                        onChange={(e, value) => setcurrency(value)}
                                                        size={'small'}
                                                        renderInput={(params) => <TextField {...params} label="Choose a Currency" />}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3 row">
                                            <MultiSelect label={'Skills'}
                                                allList={allSkills}
                                                arrayList={Skills}
                                                onSelect={handleClick}
                                                removeSkill={HandleRemove}
                                                SkillSelected={SelectedSkills}
                                                Search={(e) => setsearchSkills(e.target.value)}
                                                SearchValue={searchSkills}
                                                handleAddSkill={AddSkill} />
                                        </div>



                                        <div className="mb-3 row">
                                            <InputTextField label={'Min Experience'} onKeyDown={PreventNonNumeric} type={'number'} onchange={(e) => setInitialValue({ ...InitialValue, min_experience: e.target.value })} placeholder={'Min Experience'} name={'min_experience'} isRequired={true} value={data.min_experience} isDisabled={false} />
                                        </div>

                                        <div className="mb-3 row">
                                            <InputTextField label={'Max Experience'} onKeyDown={PreventNonNumeric} type={'number'} onchange={(e) => setInitialValue({ ...InitialValue, max_experience: e.target.value })} placeholder={'Max Experience'} name={'max_experience'} isRequired={true} value={data.max_experience} isDisabled={false} />
                                        </div>



                                        <div className="row mb-3">
                                            <InputTextField label={'Location'} type={'text'} onchange={(e) => setInitialValue({ ...InitialValue, location: e.target.value })} placeholder={'Location'} name={'location'} isRequired={true} value={data.location} isDisabled={false} />
                                        </div>
                                        <div className="row mb-3">
                                            <SelectField label={'Education'} options={educationList} onchange={(e) => setInitialValue({ ...InitialValue, education_requirements: e.target.value })} placeholder={'Choose Education Qualification'} name={'education_requirements'} isRequired={true} value={data.education_requirements} isDisabled={false} />
                                        </div>
                                        <div className="row mb-3">
                                            <InputTextField label={'Specification'} type={'text'} onchange={(e) => setInitialValue({ ...InitialValue, education_type: e.target.value })} placeholder={'Choose Education Specification'} name={'education_type'} isRequired={true} value={data.education_type} isDisabled={false} />
                                        </div>
                                        <div className="row mb-3">
                                            <SelectField label={'Job Type'} options={EmployeeType} onchange={(e) => setInitialValue({ ...InitialValue, job_type: e.target.value })} placeholder={'Choose Employee type'} name={'job_type'} isRequired={true} value={data.job_type} isDisabled={false} />
                                        </div>

                                        {id &&
                                            <InputRadio label={'Is Active'} value={data.is_active} onchange={(e) => setInitialValue({ ...InitialValue, is_active: e.target.checked })} />
                                        }

                                    </div>

                                </div>

                            </div>
                            {/* {edit && */}

                            {/* {!data.winner && */}
                            <div className="card-footer text-end">
                                <div className="col-sm-9 offset-sm-3">

                                    <button className="btn btn-primary mr-3" type="button" onClick={(e) => {
                                        handleSubmit(e)
                                    }} >{BtnLoading && <i class="fas fa-circle-notch fa-spin"> </i>} {("Submit")}</button>
                                    {/* <button className="btn btn-light" defaultValue="Cancel">{t("Reset")}</button> */}


                                    {/* {IsModalOpen &&
                                        <ConfirmationModal title={''} message={('Are you sure you want to update status ?')} onSubmit={(e) => handleSubmit(e)} onClose={() => setIsModalOpen(false)} ModalOpen={IsModalOpen} />
                                    } */}

                                </div>
                            </div>

                        </form>


                    </>}

            </div>


        </>
    )
}

export default Application
export const EmployeeType = [
    { value: 'FULL_TIME', id: 0 },
    { value: 'PART_TIME', id: 1 },
    // { value: 'SELF_EMPLOYED', id: 2 },
    { value: 'FREELANCE', id: 3 },
    { value: 'INTERNSHIP', id: 4 },
    { value: 'TRAINEE', id: 5 },
    { value: 'CONTRACT', id: 6 },
    // { value: 'TEMPORARY', id: 7 },
]