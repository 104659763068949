import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Layout from '../../components/Layout/Layout'
// import { ManageSlots, ManageUsers } from './UserLogic'
import { protocol } from '../../Functionalities/config'
import axios from 'axios'
import { useContext } from 'react'
import { Context } from '../../components/context/context'
import { ShowSuccessMessage, ShowFailedMessage } from '../../Functionalities/functions'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../components/Modal/CustomModal'
import { ManagePagination } from '../../Functionalities/PaginationLogic'
import { validateEmail } from '../../Functionalities/functions'
import TextField from '../../components/InputFields/TextField'
import ConfirmationModal from '../../components/Modal/confirmationModal'
import { Download } from '../Export/download'
import { ManageAPIs } from '../../Functionalities/Logic'
import { Button } from '@mui/material'
import { FileDownload, NavigateBefore } from '@mui/icons-material'
import styled from '@emotion/styled'


let path = "/admins/supervisor/";

const Admins = () => {
    // const slots = JSON.parse(localStorage.getItem('slots'))
    const { t } = useTranslation()
    const history = useHistory()
    const { state } = useLocation()
    const { IsSuperAdmin, setIsSuperAdmin, } = useContext(Context)
    const [IsModalOpen, setIsModalOpen] = useState(false)
    const [IsConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState([])
    const [ClearFilterData, setClearFilterData] = useState(false)
    const [FilterData, setFilterData] = useState({
        // is_staff: false,
        id: '',
        username: "",
        mobile: "",
        email: '',
    })
    const [IsExportModalOpen, setIsExportModalOpen] = useState(false)

    const [ItemId, setItemId] = useState('')

    const [currentPage, setcurrentPage] = useState('')
    const [TotalPages, setTotalPages] = useState('')







    useEffect(() => {
        let param = ''
        if (state?.id) {
            param = `supervisor_id=${state?.id}`
            setFilterData({ ...FilterData, id: state?.id })
            // console.log({ state })
        }
        GetUsers(param)

    }, [])

    useEffect(() => {
        if (ClearFilterData) {
            GetUsers()
        }
    }, [ClearFilterData])




    const GetUsers = (params) => {

        let param = params
        if (param) {
            param += `&id=${FilterData.id}&name=${FilterData.username}&mobile=${FilterData.mobile}`
        } else {
            param = `id=${FilterData.id}&name=${FilterData.username}&mobile=${FilterData.mobile}`
        }
        let path = '/user/adminadd/'

        let result = ManageAPIs('get', path, '', param ? param : '',)

        result.then((res) => {
            // console.log(res)
            setClearFilterData(false)
            if (params?.includes('pagination=0')) {
                if (res.data) {
                    setdata(res.data)
                    setTotalPages(res.data.num_pages)
                    setcurrentPage(res.data.current_page)

                    setloading(false)

                } else {
                    setdata([])

                    setloading(false)


                }
            } else {

                if (res.data.results.length >= 1) {
                    setdata(res.data.results)
                    setTotalPages(res.data.num_pages)
                    setcurrentPage(res.data.current_page)
                    setNext(res.data.next)
                    setPrev(res.data.previous)

                    setloading(false)

                } else {
                    setdata([])

                    setloading(false)
                    setNext()
                    setPrev()

                }
            }
        })
            .catch((err) => {
                console.log(err)
            })

    }


    // const handleDelete = () => {
    //     let params = `supervisor_id=${ItemId}`

    //     let result = ManageUsers('delete', '', params, path)

    //     result.then((res) => {
    //         console.log(res)
    //         if (res.data.Status) {
    //             ShowSuccessMessage(res.data.Message)
    //             GetUsers()
    //             setIsConfirmModalOpen(false)
    //         } else {
    //             ShowFailedMessage(res.data.Error)
    //         }

    //     })

    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }


    const HandleFilter = () => {


        GetUsers()
        setIsModalOpen(false)

    }




    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setdata(response.data.results)
                setTotalPages(response.data.num_pages)
                setcurrentPage(response.data.current_page)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        let path = Next.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setdata(response.data.results)
                setTotalPages(response.data.num_pages)
                setcurrentPage(response.data.current_page)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


console.log({IsSuperAdmin})




    return (
        <>
            <div class="card ">
                <div class="d-flex justify-content-between card-header pb-0 ">
                    <h5>{'Admins'} </h5>
                    <div>
                        <button class="btn btn-primary " onClick={() => setIsModalOpen(true)} >Filter</button>
                        {/* <button className="btn btn-outline-primary" style={{ marginLeft: '.5rem' }} onClick={() => { setIsExportModalOpen(true); GetUsers('&pagination=0') }} >Export</button> */}
                    </div>
                </div>
                <div className="">
                    {IsModalOpen &&
                        <CustomModal
                            ModalOpen={IsModalOpen}
                            title={'FilterData'}
                            onClose={() => { setIsModalOpen(false) }}
                            PrimaryButtonName={'Submit'}
                            PrimaryButtonFunc={() => HandleFilter()}
                        >
                            <div className="row">
                                <div className="col-6">
                                    <TextField label={'Id'} placeholder={'Enter user id'} type={'number'} onchange={(e) => setFilterData({ ...FilterData, id: e.target.value })} value={FilterData?.id} />
                                </div>
                                <div className="col-6">
                                    <TextField label={'Name'} placeholder={'Enter Name'} type={'text'} onchange={(e) => setFilterData({ ...FilterData, username: e.target.value })} value={FilterData?.username} />
                                </div>
                                <div className="col-6 mt-2">
                                    <TextField label={'Mobile'} placeholder={'Enter Mobile'} type={'number'} onchange={(e) => setFilterData({ ...FilterData, mobile: e.target.value })} value={FilterData?.mobile} />
                                </div>


                            </div>
                        </CustomModal>
                    }


                    {IsExportModalOpen &&
                        <CustomModal
                            ModalOpen={IsExportModalOpen}
                            // title={'exporting'}
                            onClose={() => { setIsExportModalOpen(false) }}
                            PrimaryButtonName={'download'}
                            ExportButton={<Download title={'Supervisor'} data={data} isDisabled={loading} />}
                        >
                            <div className="row">
                                {loading ?
                                    <Loader />
                                    :
                                    <div className='d-flex justify-content-center'>
                                        <h6>
                                            Your file is ready to download
                                        </h6>

                                    </div>
                                }
                            </div>
                        </CustomModal>
                    }


                </div>


                <div className="row card-body">

                    <div className="d-flex justify-content-between">
                        <div>
                            {IsSuperAdmin &&
                                <Link to={`/create-admin`} class="btn btn-primary "  >Add Admin</Link>
                            }

                            {((FilterData?.username || FilterData?.id || FilterData?.mobile) &&
                                <div className="d-flex align-items-center">

                                    <p className="mb-0">FilterData Applied</p>
                                    <button className="font-primary btn  m-0" onClick={() => { setFilterData({ ...FilterData, id: '', username: '', mobile: '' }); setClearFilterData(true); }}>
                                        <i className="fas fa-times-circle " style={{ marginRight: '.25rem' }}></i>
                                        Clear FilterData
                                    </button>
                                </div>
                            )}
                        </div>


                        <div>
                            <button className='btn btn-primary' disabled={!Prev ? true : false} onClick={HandlePrev}>Prev </button>
                            {/* {` ${currentPage}/${TotalPages}`} */}
                            <button className='btn btn-primary ' style={{ marginLeft: '.25rem' }} disabled={!Next ? true : false} onClick={HandleNext}> Next</button>
                        </div>
                    </div>

                    {data.length ?
                        <table className="display dataTable" id="basic-3" role="grid" aria-describedby="basic-3_info">
                            <thead>
                                <tr role="row">

                                    <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '75.8875px' }}>
                                        {("Id")} </th>
                                    <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '275.8875px' }}>
                                        {("Name")} </th>
                                    <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                        {("Email")}</th>
                                    <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                        {("Mobile")}</th>
                                    {/* <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                        {("Handlers of")}</th>

                                    <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '180px' }}>
                                        {("View Agents")}
                                    </th> */}
                                    <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '100px' }}>
                                        {("")}

                                    </th>




                                </tr>


                            </thead>



                            <tbody>
                                {data.map((item) => (
                                    <tr role="row" className="odd ">

                                        <td>{item.id}</td>
                                        <td>{`${item.first_name && item.first_name}  ${item.middle_name != null ? item.middle_name : ''}  ${item.last_name && item.last_name}`}  </td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile}</td>
                                        <td className='d-flex justify-content-center'>
                                            <ColorButton size='small' variant="contained" color='secondary' onClick={() => history.push(`/admin-details/${item.id}`)} >View</ColorButton>
                                        </td>

                                        {/* <td>
                                            {item.for_agent &&
                                                <Link className='px-3 mx-2 btn btn-secondary ' to={{ pathname: `/agents`, state: { supervisorId: item.id } }} >View</Link>
                                            }
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-between'>
                                                <button type='button' className='px-3 mx-2 btn btn-danger ' onClick={() => { setIsConfirmModalOpen(true); setItemId(item.id) }} >Delete</button>

                                            </div>
                                        </td> */}

                                    </tr>
                                ))}
                            </tbody>

                            {/* 
                            {IsConfirmModalOpen &&
                                <ConfirmationModal title={''} message={('Are you sure you want to remove ?')} onSubmit={() => handleDelete()} onClose={() => setIsConfirmModalOpen(false)} ModalOpen={IsConfirmModalOpen} />
                            } */}
                        </table>


                        :
                        <h5>
                            {loading ? <Loader /> :
                                <div className="d-flex justify-content-center">
                                    <h6>{t("No Data Found")}</h6>
                                </div>}
                        </h5>
                    }
                </div>
            </div>
        </ >
    )
}

export default Admins


const ColorButton = styled(Button)(({ theme }) => ({
    // color: theme.palette.getContrastText('#2c245d'),
    backgroundColor: '#2c245d',
    '&:hover': {
        backgroundColor: '#2c245d',
    },
}));