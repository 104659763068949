import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { Slide, toast } from "react-toastify";
import BackButtonComponent from "../../components/backButtonComponent/backButtonComponent";
import Layout from "../../components/Layout/Layout";
import { ManageSlots, ManageUsers } from "./UserLogic";
import {
  ShowSuccessMessage,
  ShowFailedMessage,
  restrictWhiteSpace,
  PreventNonNumeric,
  addDomainInImage,
  validateEmail,
} from "../../Functionalities/functions";
import { useTranslation } from "react-i18next";
import {
  lengthValidation,
  userValidation,
} from "../../Functionalities/Validations";
import { Link } from "react-router-dom";
import { ConvertException } from "../../Functionalities/CTMValidation";
import TextField from "../../components/InputFields/TextField";
import UseForm from "../../components/Hooks/UseForm";
import SelectField from "../../components/InputFields/SelectField";
import { ManageAPIs } from "../../Functionalities/Logic";
import Loader from "../../components/Loader/Loader";
import { Button } from "@mui/material";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import ExperienceCard from "../../components/Card/ExperienceCard";
import { EmployeeType } from "../JobPost/jobDetails";

let path = "/admins/supervisor/";

const UserDetails = () => {
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const [isButtonActive, setisButtonActive] = useState(true);
  const [BtnLoading, setBtnLoading] = useState(false);
  const [InitialValue, setInitialValue] = useState({
    date_of_birth: "",
    email: "",
    facebook_id: "",
    first_name: "",
    gender: "",
    google_id: "",
    id: "",
    image: "",
    is_active: "",
    is_staff: "",
    last_name: "",
    linkedin_id: "",
    middle_name: "",
    mobile: "",
    resume: "",
    role: "",
    skill_count: "",
    username: "",
    password: "",
    confirm_password: "",
    skill_details: [],
    education_details: [],
    experience_details: []
  });
  const [loading, setloading] = useState(false);
  const [IsEditable, setIsEditable] = useState(false);
  const [ClearState, setClearState] = useState(false);
  const [data, setData] = UseForm(InitialValue, ClearState, setClearState);
  const [changePassword, setchangePassword] = useState(false)


  useEffect(() => {
    if (state?.data) {
      setInitialValue({
        ...state.data,
        mobile:
          state.data.google_id == state.data.mobile
            ? ""
            : state.data?.facebook_id == state.data.mobile
              ? ""
              : state.data?.linkedin_id == state.data.mobile
                ? ""
                : state.data.mobile,
      });
    } else {
      if (!pathname.includes("/profile")) {
        GetUsers();
      } else {
        let ProfileData = JSON.parse(localStorage.getItem("jobportal_data"));
        console.log({ ProfileData });
        setInitialValue(ProfileData);
      }
    }
  }, [state]);

  const GetUsers = () => {
    setloading(true);

    let param = `id=${id}`;

    let path = `/user/listUsers/`;

    let result = ManageAPIs("get", path, "", param);

    result
      .then((res) => {
        // console.log(res)

        if (res.data.results) {
          setInitialValue(res.data.results[0]);
          setloading(false);
        } else {
          setInitialValue({});
          setloading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ data });
    let validation = userValidation(data, [
      "username",
      "first_name",
      "last_name",
      "email",
      "mobile",
      "password",
      "confirm_password",
      // "gender",
    ]);

    if (!validation) {
      return;
    }

    if (!validateEmail(data.email)) {
      return ShowFailedMessage("Invalid email");
    }

    if (data.password !== data.confirm_password) {
      ShowFailedMessage(`password does not match`);
      return;
    }

    var formdata = new FormData();

    formdata.append("id", data.id);
    // formdata.append("username", data.username);
    formdata.append("email", data.email);
    data.password && formdata.append("password", data.password);
    formdata.append("first_name", data.first_name);
    formdata.append("middle_name", data.middle_name ? data.middle_name : "");
    formdata.append("last_name", data.last_name);
    // formdata.append("gender", data.gender);
    formdata.append("mobile", data.mobile);
    // id && formdata.append("is_active", data.is_active);


    // return
    let path = `/user/adminadd/`;

    let result = ManageAPIs("update", path, formdata, "");

    result
      .then((res) => {
        setisButtonActive(false);
        setBtnLoading(true);
        console.log(res);
        if (res.data.Status) {
          ShowSuccessMessage(res.data.Message);
          setClearState(true);
          setisButtonActive(true);
          setBtnLoading(false);

          if (res.data.Message.includes('updated')) {
            setchangePassword(false)
          }
        } else if (res.data.Message.includes("Excepction occured")) {
          console.log(res.data.Data.referral_code);
          console.log(res.data.Data);
          ConvertException(res.data.Data);
          setisButtonActive(true);
          setBtnLoading(false);
        } else {
          ShowFailedMessage(res.data.Message);
          setisButtonActive(true);
          setBtnLoading(false);
        }
      })

      .catch((err) => {
        console.log(err);
        setisButtonActive(true);
        setBtnLoading(false);
      });
  };

  return (
    <>
      <div className="card">
        <BackButtonComponent />

        {/* {noData ?
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                        <h3>{t("No Data Found")}</h3>
                    </div> : */}

        {!loading ? (
          <>
            <div className="card-header d-flex justify-content-between pb-0">
              <h5>{pathname.includes("/profile") ? "Profile" : "User Details"}</h5>
            </div>

            <form className="form theme-form">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="mb-3 row">
                      <TextField
                        label={"First Name"}
                        type={"text"}
                        onchange={setData}
                        name={"first_name"}
                        value={data.first_name}
                        disabled={id}
                      />
                    </div>
                    <div className="mb-3 row">
                      <TextField
                        label={"Middle Name"}
                        type={"text"}
                        onchange={setData}
                        name={"middle_name"}
                        value={data.middle_name}
                        disabled={id}
                      />
                    </div>
                    <div className="mb-3 row">
                      <TextField
                        label={"Last Name"}
                        type={"text"}
                        onchange={setData}
                        name={"last_name"}
                        value={data.last_name}
                        disabled={id}
                      />
                    </div>

                    <div className="mb-3 row">
                      <TextField
                        label={"Mobile"}
                        type={"number"}
                        onchange={setData}
                        name={"mobile"}
                        value={data.mobile}
                        disabled={id}
                      />
                    </div>

                    <div className="mb-3 row">
                      <TextField
                        label={"Email"}
                        type={"text"}
                        onchange={setData}
                        name={"email"}
                        value={data.email}
                        disabled={id}
                      />
                    </div>

                    <div className="mb-3 row">
                      <TextField
                        label={"Username"}
                        type={"text"}
                        onchange={setData}
                        name={"username"}
                        value={data.username}
                        disabled={id}
                      />
                    </div>

                    {!pathname.includes("/profile") &&
                      <>

                        <div className='row mt-4'>
                          <div className="col-3 d-flex align-items-center">
                            <label className={'col-form-label'}>Education Details</label>
                          </div>

                          <div className="col-12" >
                            {data.education_details.map(item => (

                              <ExperienceCard
                                jobTitle={item.degree}
                                company={item.school}
                                jobType={item.grade}
                                startDate={item.start_date}
                                endDate={item.end_date}
                                description={item.description}
                              // currentlyWorking={item.currently_working}
                              />
                            ))}


                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className="col-3 d-flex align-items-center">
                            <label className={'col-form-label'}>Experience</label>
                          </div>

                          <div className="col-12" >
                            {data.experience_details.map((item, idx) => (
                              <ExperienceCard
                                jobTitle={item.title}
                                company={item.company_name}
                                jobType={EmployeeType.find(itm => itm.id === item.employement_type).value.replace('_', ' ')}
                                startDate={item.start_date}
                                endDate={item.end_date}
                                description={item.description}
                                currentlyWorking={item.currently_working}
                              />
                            ))}


                          </div>
                        </div>

                        <div className='row'>
                          <div className="col-3 d-flex align-items-center">
                            <label className={'col-form-label'}>Skills</label>
                          </div>

                          <div className="col-12 px-5" >


                            <ul className="row" style={{ listStyle: 'disc' }}>
                              {data.skill_details.map(item => (
                                <li className="col-lg-4">
                                  {item.skill.name}
                                </li>
                              ))}
                            </ul>


                          </div>
                        </div>
                      </>
                    }

                    {pathname.includes("/profile") &&
                      <>

                        <div className="mb-3 row">

                          <div>
                            <Button
                              size="small"
                              variant="contained"
                              color="error"
                              onClick={() => setchangePassword(!changePassword)}
                            >
                              {changePassword ? "Cancel" : "change password"}
                            </Button>
                          </div>

                        </div>

                        {changePassword &&
                          <>
                            <div className="mb-3 row">
                              <TextField
                                isRequired={true}
                                label={"Password"}
                                type={"password"}
                                placeholder={"Enter password"}
                                onchange={setData}
                                name={"password"}
                                value={data.password}
                                disabled={IsEditable}
                              />
                            </div>
                            <div className="mb-3 row">
                              <TextField
                                isRequired={true}
                                label={"Confirm password"}
                                type={"password"}
                                placeholder={"Enter confirm password"}
                                onchange={setData}
                                name={"confirm_password"}
                                value={data.confirm_password}
                                disabled={IsEditable}
                              />
                            </div>
                          </>
                        }
                      </>
                    }
                  </div>
                </div>
              </div>

              <div className="card-footer text-end">
                <div className="col-sm-9 offset-sm-3">
                  {data.resume && (
                    <a
                      href={data.resume}
                      download
                      target={'_blank'}
                      className="btn btn-primary "
                      style={{ marginRight: "1rem" }}
                      type="button"
                    >
                      {" "}
                      {"Download Resume"}
                    </a>
                  )}
                  {(pathname.includes('/profile')) &&
                    <button className="btn btn-success mr-3" type="button" onClick={handleSubmit} >{BtnLoading && <i class="fas fa-circle-notch fa-spin"> </i>} {("Update")}</button>
                  }
                  {/* <button className="btn btn-light" defaultValue="Cancel">{t("Reset")}</button> */}
                </div>
              </div>
            </form>
          </>
        ) : (
          <Loader />
        )}
        {/* } */}
      </div>
    </>
  );
};

export default UserDetails;
