import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { protocol } from '../../Functionalities/config'
import { ShowSuccessMessage, ShowFailedMessage } from '../../Functionalities/functions'
import Loader from '../../components/Loader/Loader'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../components/Modal/CustomModal'
import { ManagePagination } from '../../Functionalities/PaginationLogic'
import { validateEmail } from '../../Functionalities/functions'
import ConfirmationModal from '../../components/Modal/confirmationModal'
import { Download } from '../Export/download'
import { ManageAPIs } from '../../Functionalities/Logic'
import { Autocomplete, Button, TextField } from '@mui/material'
import styled from '@emotion/styled'
import InputField from '../../components/InputFields/TextField'
import moment from 'moment'


const EmployeeDetails = () => {
    const { pathname } = useLocation()
    const history = useHistory()
    const [IsModalOpen, setIsModalOpen] = useState(false)
    const [JobPostModal, setJobPostModal] = useState(false) //for fetch job post from modal
    const [IsConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [loading, setloading] = useState(true)
    const [data, setdata] = useState([])
    const [ClearFilterData, setClearFilterData] = useState(false)
    const [FilterData, setFilterData] = useState({
        // is_staff: false,
        id: '',
        first_name: "",
        job_applied: '',
        interview_scheduled: '',
        joining_date: '',
    })

    const [IsExportModalOpen, setIsExportModalOpen] = useState(false)

    useEffect(() => {
        if (pathname !== '/users' && !pathname.includes('admin')) {
            setJobPostModal(true)
            setloading(false)
        }

        return () => {
            setJobPostModal(false)
        }
    }, [pathname])


    useEffect(() => {
        GetUsers()
    }, [])






    useEffect(() => {
        if (ClearFilterData) {
            GetUsers()
        }
    }, [ClearFilterData])




    const GetUsers = (params) => {
        console.log({ FilterData })
        let filters = `&id=${FilterData.id}&first_name=${FilterData.first_name}&job_applied=${FilterData.job_applied}&joining_date=${FilterData.joining_date}${FilterData.interview_scheduled && `&interview_scheduled_gte=${FilterData.interview_scheduled.slice(0, 10) + ' ' + '00:00:00'}&interview_scheduled_lte=${FilterData.interview_scheduled.slice(0, 10) + ' ' + '23:59:59'}`}`
        let param = params

        if (param) {
            param += filters
        } else {
            param = filters
        }

        let path = '/aliens'

        let result = ManageAPIs('get', path, '', param ? param : '',)

        result.then((res) => {
            // console.log(res)
            setClearFilterData(false)
            if (params?.includes('pagination=0')) {
                if (res.data) {
                    setdata(res.data)

                    setloading(false)

                } else {
                    setdata([])

                    setloading(false)


                }
            } else {

                if (res.data.results.length >= 1) {
                    setdata(res.data.results)

                    setNext(res.data.next)
                    setPrev(res.data.previous)

                    setloading(false)

                } else {
                    setdata([])
                    setloading(false)
                    setNext()
                    setPrev()
                }


            }
        })
            .catch((err) => {
                console.log(err)
            })

    }


    const handleDelete = (id) => {
        let params = ``
        let path = '/user/listUsers/'

        let result = ManageAPIs('delete', path, '', params,)

        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetUsers()
                setIsConfirmModalOpen(false)
            } else {
                ShowFailedMessage(res.data.Error)
            }

        })

            .catch((err) => {
                console.log(err)
            })
    }


    const HandleFilter = () => {
        GetUsers()
        setIsModalOpen(false)

    }




    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setdata(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        let path = Next.replace('http:', protocol.replace('//', ''))

        // return console.log({path})
        let result = ManagePagination(path, '')

        result.then(function (res) {
            console.log((res.data));
            if (res.data.results) {
                setdata(res.data.results)
                setNext(res.data.next)
                setPrev(res.data.previous)
                setloading(false)

            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    const Status = [
        { id: 1, value: "Approved" },
        { id: 2, value: "Rejected" },
    ];





    return (
        <>
            <div class="card ">
                <div class="d-flex justify-content-between card-header pb-0 ">
                    <h5>{pathname.replaceAll('/', ' ').replaceAll('-', ' ')} </h5>
                    <div>
                        <button class="btn btn-primary " onClick={() => setIsModalOpen(true)} >Filter</button>
                        <button className="btn btn-outline-primary" style={{ marginLeft: '.5rem' }} onClick={() => { setIsExportModalOpen(true); GetUsers('pagination=false') }} >Export</button>
                    </div>
                </div>
                <div className="">
                    {IsModalOpen &&
                        <CustomModal
                            ModalOpen={IsModalOpen}
                            title={'FilterData'}
                            onClose={() => { setIsModalOpen(false) }}
                            PrimaryButtonName={'Submit'}
                            PrimaryButtonFunc={() => HandleFilter()}
                        >
                            <div className="row">
                                <div className="col-6">
                                    <InputField label={'Id'} placeholder={'Enter Employee id'} type={'number'} onchange={(e) => setFilterData({ ...FilterData, id: e.target.value })} value={FilterData?.id} />
                                </div>
                                <div className="col-6">
                                    <InputField label={'First Name'} placeholder={'Enter Employee First Name'} type={'text'} onchange={(e) => setFilterData({ ...FilterData, first_name: e.target.value })} value={FilterData?.first_name} />
                                </div>
                                <div className="col-6 mt-2">
                                    <InputField label={'Job'} placeholder={'Enter job title'} type={'text'} onchange={(e) => setFilterData({ ...FilterData, job_applied: e.target.value })} value={FilterData?.job_applied} />
                                </div>
                                <div className="col-6 mt-2">
                                    <InputField label={'Interview scheduled'} type={'date'} onchange={(e) => setFilterData({ ...FilterData, interview_scheduled: e.target.value })} value={FilterData?.interview_scheduled} />
                                </div>
                                <div className="col-6 mt-2">
                                    <InputField label={'Joining date'} type={'date'} onchange={(e) => setFilterData({ ...FilterData, joining_date: e.target.value })} value={FilterData?.joining_date} />
                                </div>


                            </div>
                        </CustomModal>
                    }


                    {IsExportModalOpen &&
                        <CustomModal
                            ModalOpen={IsExportModalOpen}
                            title={'exporting'}
                            onClose={() => { setIsExportModalOpen(false) }}
                            PrimaryButtonName={'download'}
                            ExportButton={<Download title={'employeeDetails'} data={data} isDisabled={loading} />}
                        >
                            <div className="row">
                                {loading ?
                                    <Loader />
                                    :
                                    <div className='d-flex justify-content-center'>
                                        <h6>
                                            Your file is ready to download
                                        </h6>

                                    </div>
                                }
                            </div>
                        </CustomModal>
                    }


                </div>


                <div className="row card-body">

                    <div className="d-flex justify-content-between">
                        <div>
                            <Link to={`/create-alien`} class="btn btn-primary "  >Create Aliens</Link>

                            {((FilterData?.id || FilterData?.job_applied || FilterData?.first_name || FilterData?.interview_scheduled || FilterData?.joining_date) &&
                                <div className="d-flex align-items-center">

                                    <p className="mb-0">FilterData Applied</p>
                                    <button className="font-primary btn  m-0" onClick={() => {
                                        setFilterData({
                                            ...FilterData, id: '',
                                            first_name: "",
                                            job_applied: '',
                                            interview_scheduled: '',
                                            joining_date: '',
                                        }); setClearFilterData(true);
                                    }}>
                                        <i className="fas fa-times-circle " style={{ marginRight: '.25rem' }}></i>
                                        Clear FilterData
                                    </button>
                                </div>
                            )}
                        </div>


                        <div>
                            <button className='btn btn-primary' disabled={!Prev ? true : false} onClick={HandlePrev}>Prev </button>
                            {/* {` ${currentPage}/${TotalPages}`} */}
                            <button className='btn btn-primary ' style={{ marginLeft: '.25rem' }} disabled={!Next ? true : false} onClick={HandleNext}> Next</button>
                        </div>
                    </div>
                    <div className="table-responsive custom-scrollbar">

                        <div id="basic-3_wrapper" className="dataTables_wrapper">

                            {data.length ?
                                <table className="display dataTable" id="basic-3" role="grid" aria-describedby="basic-3_info">
                                    <thead>
                                        <tr role="row">

                                            <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '75.8875px' }}>
                                                {("Sl no")} </th>
                                            <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '75.8875px' }}>
                                                {("Emp Id")} </th>
                                            <th className="_desc" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-sort="descending" aria-label="Age: activate to sort column ascending" style={{ width: '275.8875px' }}>
                                                {("Name")} </th>
                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Job applied")}</th>
                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Status")}</th>


                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Interview Scheduled")}</th>

                                            <th className="" tabIndex={0} aria-controls="basic-3" colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '257.337px' }}>
                                                {("Joining Date")}</th>



                                            <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '120px' }}>
                                                {("")}

                                            </th>

                                        </tr>


                                    </thead>



                                    <tbody>
                                        {data.map((item, idx) => (
                                            <tr role="row" className="odd ">

                                                <td>{idx + 1}</td>
                                                <td>{item.id}</td>
                                                <td>{`${item.first_name && item.first_name}  ${item.middle_name !== null ? item.middle_name : ''}  ${item.last_name && item.last_name}`}  </td>
                                                <td>{item.job_applied}</td>
                                                <td>{Status.find(itm => itm.id == item.status)?.value}</td>
                                                <td>{item.interview_scheduled !== null && moment(item.interview_scheduled).format('MMMM Do YYYY')}</td>
                                                <td>{item.joining_date !== null && moment(item.joining_date).format('MMMM Do YYYY')}</td>

                                                <td className='d-flex justify-content-center'>
                                                    <ColorButton size='small' variant="contained" color='secondary' onClick={() => history.push({ pathname: `/alien-detail/${item.id}`, state: { data: item } })} >
                                                        View
                                                    </ColorButton>
                                                </td>



                                            </tr>
                                        ))}
                                    </tbody>


                                    {/* {IsConfirmModalOpen &&
                                        <ConfirmationModal title={''} message={('Are you sure you want to remove ?')} onSubmit={() => handleDelete()} onClose={() => setIsConfirmModalOpen(false)} ModalOpen={IsConfirmModalOpen} />
                                    } */}

                                    {/* {ModalOpen &&
                                        <CustomModal PrimaryButtonName={'Submit'} PrimaryButtonFunc={ChangeStatus} ModalOpen={ModalOpen} onClose={() => setModalOpen(false)} >
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label className='col-form-label'>{'Comment'} </label>
                                                </div>
                                                <div className='col-12'>
                                                    <textarea className='form-control' name="" id="" rows="4"
                                                        onChange={(e) => setStatusUpdateData({ ...StatusUpdateData, description: e.target.value })} value={StatusUpdateData?.description}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </CustomModal>
                                    } */}
                                </table>


                                :
                                <h5>
                                    {loading ? <Loader /> :
                                        <div className="d-flex justify-content-center">
                                            {/* {data.length > } */}

                                            <h6>No Data Found </h6>


                                        </div>}
                                </h5>
                            }

                            {/* {JobPostModal &&
                                <CustomModal ModalOpen={JobPostModal} onClose={() => setJobPostModal(false)} PrimaryButtonFunc={() => { GetUsers(`user_list=${JSON.stringify(UsersArray)}`); setJobPostModal(false) }} PrimaryButtonName={'Apply'} >
                                    <div className='row'>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box"
                                            options={JobListArray}
                                            fullWidth
                                            value={JobId}
                                            onChange={(e, value) => setJobId(value)}
                                            size={'small'}
                                            renderInput={(params) => <TextField {...params} label="Choose a job post" placeholder={'Type job id or job name'} />}
                                       
                                        />
                                    </div>
                                </CustomModal>
                            } */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeDetails
export const ColorButton = styled(Button)(({ }) => ({
    // color: theme.palette.getContrastText('#2c245d'),
    backgroundColor: '#2c245d',
    '&:hover': {
        backgroundColor: '#2c245d',
        color: '#dedede'
    },
}));