import React, { Component, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { useTranslation } from 'react-i18next';

const RichTextEditorComponent = ({ postData, onChange, label, isRequired }) => {
    // const [content, setContent] = useState(postData)

    return (
        <div className='row'>
            <div className='col-3 ' >
                <label className='col-form-label'>{label} {isRequired ? '*' : ''}</label>
            </div>

            <div className='col-9'>

                {/* <h2>Using CKEditor 5 build in React</h2> */}
                <CKEditor
                    editor={ClassicEditor}
                    // data="<p>Hello from CKEditor 5!</p>"
                    data={postData}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        console.log({ event, editor, data });
                        // setContent(data)
                        onChange(data)
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
                {/* <button className="btn btn-primary pull-right m-t-10" onClick={(e) => { e.preventDefault(); onChange(content) }}>{("Ok")}</button> */}
            </div>
        </div>

    );
}

export default RichTextEditorComponent;