import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { PreventNonNumeric } from '../../Functionalities/functions';
;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

export default function CustomModal(props) {
    const [ConfirmMessage, setConfirmMessage] = React.useState('')
    const [ConfirmNotification, setConfirmNotification] = React.useState('')
    const [ConfirmDelete, setConfirmDelete] = React.useState('')
    const [ConfirmWinner, setConfirmWinner] = React.useState('')


    // console.log('______________' + props.ModalData)
    // console.log(JSON.stringify(props.PrimaryButtonLink))
    // console.log('selected user', props.selectedUser)

    React.useEffect(() => {
        setConfirmWinner('')
    }, [props.IsClearState])

    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.ModalOpen}
                onClose={props.onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                disableScrollLock={true}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.ModalOpen}>
                    <Box sx={style} style={{ border: 'none' }}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {props.title}
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>

                        </Typography>
                        <div className=" mt-3">

                            {props.children}

                            <div className="d-flex justify-content-end mt-5 mb-3">
                                <button className="btn btn-secondary " onClick={props.onClose} style={{ marginRight: '1rem' }}>Close</button>
                                {props.PrimaryButtonFunc ?
                                    <button className="btn btn-primary " onClick={props.PrimaryButtonFunc} type="button" >{props.PrimaryButtonName}</button>
                                    :
                                    props.PrimaryButtonLink ?
                                        <Link to={props.PrimaryButtonLink} >
                                            <button className="btn btn-primary " type="button" >{props.PrimaryButtonName}</button>
                                        </Link>
                                        :
                                        props.ExportButton &&
                                        <>
                                            {props.ExportButton}
                                        </>
                                }

                            </div>

                        </div>
                    </Box>
                </Fade>

            </Modal>
        </div >
    );
}
