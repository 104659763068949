import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { url } from '../../Functionalities/config'
import { ShowFailedMessage } from '../../Functionalities/functions'
import DashboardButton from './DashboardButton'
import { ManageDashboard } from './DashboardLogic'

const Dashboard = () => {
    const [data, setdata] = useState({
        total_CAS_recieved: '',
        total_agents: '',
        total_appliaction: '',
        total_completed: '',
        total_courses: '',
        total_pending: '',
        total_students: '',
        total_supervisors: '',
        total_universities: '',
    })

    useEffect(() => {
        GetDashboardData()
    }, [])


    const GetDashboardData = () => {


        const result = ManageDashboard('get', '', '')

        result.then(function (res) {
            console.log(res.data)
            if (res.data) {
                // setdata({
                //     ...data,
                //     total_CAS_recieved: res.data.total_CAS_recieved,
                //     total_agents: res.data.total_agents,
                //     total_appliaction: res.data.total_appliaction,
                //     total_completed: res.data.total_completed,
                //     total_courses: res.data.total_courses,
                //     total_pending: res.data.total_pending,
                //     total_students: res.data.total_students,
                //     total_supervisors: res.data.total_supervisors,
                //     total_universities: res.data.total_universities,
                // })
                setdata(res.data)
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }


    console.log({ data })
    return (
        <div>
            <>

                <div class="card ">
                    <div class="d-flex justify-content-between card-header pb-0 ">
                        <h5>{'Dashboard'} </h5>

                    </div>



                    <div className="row card-body">

                        <div className="row">
                            <div className="col-4 mt-4">
                                <DashboardButton pagelink={`/application`} title={'Total Application'} count={data.total_appliaction} icon={<i className='fas fa-file sidemenu-icon'></i>} />
                            </div>
                            <div className="col-4 mt-4">
                                <DashboardButton pagelink={{ pathname: `/application`, state: { param: 'completed=1' } }} title={'Completed Applications'} count={data.total_completed} icon={<i className='fas fa-clipboard-list sidemenu-icon'></i>} />
                            </div>
                            <div className="col-4 mt-4">
                                <DashboardButton pagelink={{ pathname: `/application`, state: { param: 'completed=0' } }} title={'Pending Applications'} count={data.total_pending} icon={<i className='fas fa-clipboard sidemenu-icon'></i>} />
                            </div>
                            
                            {/* <div className="col-4 mt-4">
                                <DashboardButton pagelink={`/agents`} title={'Total Agents'} count={data.total_agents} icon={<i className='fas fa-users sidemenu-icon'></i>} />
                            </div>
                            <div className="col-4 mt-4">
                                <DashboardButton pagelink={`/university`} title={'Total University'} count={data.total_universities} icon={<i className='fas fa-university sidemenu-icon'></i>} />
                            </div> */}

                            {/* <div className="col-4 mt-4">
                                <DashboardButton pagelink={`/dashboard`} title={'Total Course'} count={data.total_courses} icon={<i className='fas fa-book sidemenu-icon'></i>} />
                            </div>
                            <div className="col-4 mt-4">
                                <DashboardButton pagelink={`/students`} title={'Total Students Enrolled'} count={data.total_students} icon={<i className='fas fa-book sidemenu-icon'></i>} />
                            </div>
                            <div className="col-4 mt-4">
                                <DashboardButton pagelink={{ pathname: `/application`, state: { status: 'CAS received' } }} title={'Total CAS Received'} count={data.total_CAS_recieved} icon={<i className='fas fa-book sidemenu-icon'></i>} />
                            </div> */}



                        </div>




                        {/* :
                        <h5>
                            {loading ? <Loader /> :
                                <div className="d-flex justify-content-center">
                                    <h6>{t("No Data Found")}</h6>
                                </div>}
                        </h5>
                    } */}
                    </div>
                </div>




            </ >
        </div>
    )
}

export default Dashboard