import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Context } from '../context/context'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


const Sidebar = () => {
    const { isSidebarOpen, forWho, user, activePage, setActivePage, IsSuperAdmin } = useContext(Context)
    const { t } = useTranslation();
    const [Location, setLocation] = useState('')

    const location = useLocation()

    useEffect(() => {
        setActivePage(location.pathname)
    }, [location])





    return (
        <>
            <div className="page-wrapper compact-wrapper compact-sidebar">
                <div className="page-body-wrapper">
                    <header className={isSidebarOpen ? "main-nav" : "main-nav close_icon"}>

                        <nav>
                            <div className="main-navbar">
                                <div className="left-arrow disabled" id="left-arrow"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg></div>
                                <div id="mainnav">
                                    <ul className="nav-menu custom-scrollbar pt-4" style={{ display: 'block' }}>

                                        <li className="back-btn">
                                            <div className="mobile-back text-end"><span>Back</span><i className="fa fa-angle-right ps-2" aria-hidden="true" /></div>
                                        </li>



                                        <li className="dropdown py-1"><Link to="/job-post" onClick={() => setActivePage("/job-post")} className={activePage == "/job-post" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title>
                                            <i class="fas fa-file sidemenu-icon"></i>
                                            <span>{'Job post'}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>

                                        </li>

                                        <li className="dropdown py-1"><Link to="/users" onClick={() => setActivePage("/users")} className={activePage == "/users" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-users sidemenu-icon"></i>
                                            <span>{'Users'}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>

                                        <li className="dropdown py-1"><Link to="/admins" onClick={() => setActivePage("/admins")} className={activePage == "/admins" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-user sidemenu-icon"></i>
                                            <span>{'Admin'}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>
                                        {/* {IsSuperAdmin && */}
                                            <li className="dropdown py-1"><Link to="/aliens" onClick={() => setActivePage("/aliens")} className={activePage == "/aliens" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                                <i class="fas fa-id-badge sidemenu-icon"></i>
                                                <span>{'Aliens'}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                            </li>
                                        {/* } */}

                                        <li className="dropdown py-1"><Link to="/matching-users" className={activePage == "/matching-users" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-sync sidemenu-icon"></i>
                                            <span>{"Matching"}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>

                                        <li className="dropdown py-1"><Link to="/applied-users" className={activePage == "/applied-users" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-hand-pointer sidemenu-icon"></i>
                                            <span>{"Applied"}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>

                                        <li className="dropdown py-1"><Link to="/shortlisted-users" className={activePage == "/shortlisted-users" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-list-ol sidemenu-icon"></i>
                                            <span>{"Shortlisted"}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>

                                        <li className="dropdown py-1"><Link to="/selected-users" className={activePage == "/selected-users" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-clipboard-check sidemenu-icon"></i>
                                            <span>{"Selected "}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>
                                        <li className="dropdown py-1"><Link to="/rejected-users" className={activePage == "/rejected-users" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-user-slash sidemenu-icon"></i>
                                            <span>{"Rejected "}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>

                                        <li className="dropdown py-1"><Link to="/profile" className={activePage == "/profile" ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title >
                                            <i class="fas fa-address-card sidemenu-icon"></i>
                                            <span>{"Profile "}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                        </li>

                                        {IsSuperAdmin &&
                                            <li className="dropdown py-1"><Link to="/settings" onClick={() => setActivePage("/settings")} className={(activePage == "/settings") ? "nav-link-active menu-title nav-link" : "nav-link menu-title"} data-bs-original-title title>
                                                <i class="fas fa-cog sidemenu-icon"></i>
                                                <span>{'Settings'}</span><div className="according-menu"><i className="fa fa-angle-right" /></div></Link>
                                            </li>
                                        }


                                    </ul>
                                </div>
                                <div className="right-arrow" id="right-arrow"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right"><line x1={5} y1={12} x2={19} y2={12} /><polyline points="12 5 19 12 12 19" /></svg></div>
                            </div>
                        </nav>
                    </header>
                </div>
            </div>
        </>

    )
}

export default Sidebar
