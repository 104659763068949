import { DELETE_API, GET_API, PATCH_API, POST_API, PUT_API } from "./APIS";


export const ManageAPIs = async (keyword, path, formData, params) => {

    if (keyword == "add") {

        // console.log(keyword, path, formData, params)

        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return

        let result = POST_API(formData, params, path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "update") {


        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return
        let result = PUT_API(formData, path,params);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }
    if (keyword == "patch") {



        var formData = new FormData()



        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        // return
        let result = PATCH_API(formData, "", path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "get") {

        let result = GET_API(params, path, '');
        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }

    if (keyword == "delete") {
        // path = "/slot"
        var formData = new FormData()

        let result = DELETE_API("", params, path);

        return await result.then((result) => {
            if (result) {
                return result;
            } else {
                return 0;
            }
        });
    }
}