import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { Slide, toast } from "react-toastify";
import BackButtonComponent from "../../components/backButtonComponent/backButtonComponent";
import Layout from "../../components/Layout/Layout";
import {
    ShowSuccessMessage,
    ShowFailedMessage,
    restrictWhiteSpace,
    PreventNonNumeric,
    addDomainInImage,
    validateEmail,
    RenderIf,
} from "../../Functionalities/functions";
import { useTranslation } from "react-i18next";
import {
    lengthValidation,
    userValidation,
} from "../../Functionalities/Validations";
import { Link } from "react-router-dom";
import { ConvertException } from "../../Functionalities/CTMValidation";
import CustomTextField from "../../components/InputFields/TextField";
import UseForm from "../../components/Hooks/UseForm";
import SelectField from "../../components/InputFields/SelectField";
import { ManageAPIs } from "../../Functionalities/Logic";
import InputRadio from "../../components/InputFields/InputRadio";
import { Context } from "../../components/context/context";
import InputTextField from '../../components/InputFields/TextField'
import ConfirmationModal from "../../components/Modal/confirmationModal";
import { Autocomplete, TextField } from '@mui/material'
import { currencies } from 'currencies.json'


let path = "/aliens/";


const EmployeeDetailsTemplate = () => {
    const { id } = useParams();
    const { pathname } = useLocation();
    const history = useHistory()
    const { IsSuperAdmin } = useContext(Context);
    const [IsConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

    const [isButtonActive, setisButtonActive] = useState(true);
    const [BtnLoading, setBtnLoading] = useState(false);

    const [InitialValue, setInitialValue] = useState({
        id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        job_applied: "",
        interview_scheduled: "",
        interview_report: null,
        interview_report_name: '',
        status: "",
        salary_approved: "",
        currency: "",
        currency_char: "",
        offer_letter_issued: null,
        offer_letter_issued_name: '',
        offer_letter_signed: null,
        offer_letter_signed_name: '',
        passport_copy: null,
        passport_copy_name: '',
        certificate_copy: null,
        certificate_copy_name: '',
        pre_medical_certificate_copy: null,
        pre_medical_certificate_copy_name: '',
        joining_date: "",
        pcc_copy: null,
        pcc_copy_name: '',
        visa_copy: null,
        visa_copy_name: '',
        vaccination_certificate: null,
        vaccination_certificate_name: '',
        Photo: null,
        Photo_name: '',
        medical_certificate: null,
        medical_certificate_name: '',
        ticket_copy: null,
        ticket_copy_name: '',
        employee_request_form: null,
        employee_request_form_name: '',
        arrival_date: "",
        pickup_details: "",
    });
    const [IsEditable, setIsEditable] = useState(false);
    const [ClearState, setClearState] = useState(false);
    const [data, setData] = UseForm(InitialValue, ClearState, setClearState);
    const [changePassword, setchangePassword] = useState(false);
    const [AdminHandledUsersList, setAdminHandledUsersList] = useState({ rejected_user: [], selected_user: [], shortlisted_user: [] })

    useEffect(() => {
        if (id) {
            GetUsers();
            // GetAdminHandledUsers()
        }
    }, [id]);


    const GetUsers = () => {
        path = "/aliens/";
        let param = `id=${id}`;
        let result = ManageAPIs("get", path, "", param);

        result
            .then((res) => {
                console.log("user data", res);
                if (res.data.results) {
                    setInitialValue(res.data.results[0]);
                }
            })

            .catch((err) => {
                console.log(err);
            });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(RenderIf(data.first_name));
        // return
        // let validation = userValidation(data, [
        //     "first_name",
        //     "last_name",
        //     "email",
        //     "mobile",
        //     "password",
        //     "confirm_password",
        //     "gender",
        // ]);

        // if (!validation) {
        //     return;
        // }




        var formdata = new FormData();

        id && formdata.append("id", data.id);
        formdata.append("first_name", RenderIf(data.first_name));
        formdata.append("middle_name", RenderIf(data.middle_name));
        formdata.append("last_name", RenderIf(data.last_name));
        formdata.append('job_applied', RenderIf(data.job_applied));
        formdata.append('interview_scheduled', RenderIf(data.interview_scheduled));
        formdata.append('interview_report', (typeof (data.interview_report) == 'object') & (data.interview_report != null) ? data.interview_report : '');
        formdata.append('status', RenderIf(data.status));
        formdata.append('salary_approved', (data.salary_approved && data.salary_approved !== null) ? data.salary_approved : '');
        formdata.append('currency_char', RenderIf(data.currency));
        formdata.append('offer_letter_issued', (typeof (data.offer_letter_issued) == 'object') && (data.offer_letter_issued) != null ? data.offer_letter_issued : '');
        formdata.append('offer_letter_signed', ((typeof (data.offer_letter_signed) == 'object') && (data.offer_letter_signed != null)) ? data.offer_letter_signed : '');
        formdata.append('passport_copy', ((typeof (data.passport_copy) == 'object') && (data.passport_copy != null)) ? data.passport_copy : '');
        formdata.append('certificate_copy', (typeof (data.certificate_copy) == 'object') && (data.certificate_copy != null) ? data.certificate_copy : '');
        formdata.append('pre_medical_certificate_copy', (typeof (data.pre_medical_certificate_copy) == 'object') && (data.pre_medical_certificate_copy != null) ? data.pre_medical_certificate_copy : '');
        formdata.append('joining_date', RenderIf(data.joining_date));
        formdata.append('pcc_copy', (typeof (data.pcc_copy) == 'object' && data.pcc_copy != null) ? data.pcc_copy : '');
        formdata.append('visa_copy', (typeof (data.visa_copy) == 'object' && data.visa_copy != null) ? data.visa_copy : '');
        formdata.append('vaccination_certificate', (typeof (data.vaccination_certificate) == 'object' && data.vaccination_certificate != null) ? data.vaccination_certificate : '');
        formdata.append('Photo', (typeof (data.Photo) == 'object' && data.photo != null) ? data.Photo : '');
        formdata.append('medical_certificate', (typeof (data.medical_certificate) == 'object' && data.medical_certificate != null) ? data.medical_certificate : '');
        formdata.append('ticket_copy', (typeof (data.ticket_copy) == 'object' && data.ticket_copy != null) ? data.ticket_copy : '');
        formdata.append('employee_request_form', (typeof (data.employee_request_form) == 'object' && data.employee_request_form != null) ? data.employee_request_form : '');
        formdata.append('arrival_date', RenderIf(data.arrival_date));
        formdata.append('pickup_details', RenderIf(data.pickup_details));

        // return
        let result = "";
        if (id) {
            result = ManageAPIs("update", path, formdata, "");
        } else {
            result = ManageAPIs("add", path, formdata, "");
        }


        result
            .then((res) => {
                setisButtonActive(false);
                setBtnLoading(true);
                console.log(res);
                if (res.data.Status) {
                    ShowSuccessMessage(res.data.Message);
                    setClearState(true);
                    setisButtonActive(true);
                    setBtnLoading(false);
                    if (res.data.Message.includes("edited")) {
                        let response = res.data.Data;

                        setInitialValue({
                            ...InitialValue,
                            id: response.id,
                            first_name: response.first_name,
                            middle_name: response.middle_name,
                            last_name: response.last_name,
                            job_applied: response.job_applied,
                            interview_scheduled: response.interview_scheduled,
                            interview_report: response.interview_report,
                            status: response.status,
                            salary_approved: response.salary_approved,
                            currency: response.currency,
                            offer_letter_issued: response.offer_letter_issued,
                            offer_letter_signed: response.offer_letter_signed,
                            passport_copy: response.passport_copy,
                            certificate_copy: response.certificate_copy,
                            joining_date: response.joining_date,
                            pcc_copy: response.pcc_copy,
                            visa_copy: response.visa_copy,
                            vaccination_certificate: response.vaccination_certificate,
                            Photo: response.Photo,
                            medical_certificate: response.medical_certificate,
                            ticket_copy: response.ticket_copy,
                            employee_request_form: response.employee_request_form,
                            arrival_date: response.arrival_date,
                            pickup_details: response.pickup_details,
                        });
                    } else {
                        setInitialValue({
                            id: "",
                            first_name: "",
                            middle_name: "",
                            last_name: "",
                            job_applied: "",
                            interview_scheduled: "",
                            interview_report: "",
                            status: "",
                            salary_approved: "",
                            currency: "",
                            offer_letter_issued: "",
                            offer_letter_signed: "",
                            passport_copy: "",
                            certificate_copy: "",
                            joining_date: "",
                            pcc_copy: "",
                            visa_copy: "",
                            vaccination_certificate: "",
                            Photo: "",
                            medical_certificate: "",
                            ticket_copy: "",
                            employee_request_form: "",
                            arrival_date: "",
                            pickup_details: "",
                        })
                    }
                } else if (res.data.Message.includes("Excepction occured")) {
                    console.log(res.data.Data.referral_code);
                    console.log(res.data.Data);
                    ConvertException(res.data.Data);
                    setisButtonActive(true);
                    setBtnLoading(false);
                } else {
                    ShowFailedMessage(res.data.Message);
                    setisButtonActive(true);
                    setBtnLoading(false);
                }
            })

            .catch((err) => {
                console.log(err);
                setisButtonActive(true);
                setBtnLoading(false);
            });
    };


    const Status = [
        { id: 1, value: "Approved" },
        { id: 2, value: "Rejected" },
    ];


    const handleChange = (e) => {
        setInitialValue({ ...InitialValue, [e.target.name]: e.target.value })
    }

    const handleDelete = () => {
        let param = `ids=[${id}]`

        let result = ManageAPIs('delete', path, '', param)
        result.then((res) => {
            console.log(res)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                history.push('/aliens')
            } else {
                ShowFailedMessage(res.data.Message)
            }
        })
            .catch((err) => {
                console.log(err)
            })
    }

    let currencyList = []


    for (let i = 0; i < currencies.length; i++) {
        currencyList.push({ label: currencies[i].name, id: currencies[i].code })
    }
    return (
        <>
            <div className="card">
                <BackButtonComponent />

                {/* {noData ?
                <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
                    <h3>{t("No Data Found")}</h3> 
                </div> : */}
                <>
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h5>
                            {window.location.pathname == `/alien-details/${id}`
                                ? "Alien Details"
                                : "Create Alien"}
                        </h5>
                        <div>
                            {/* <button type='button' className='btn btn-success mx-2'>Add Coins</button> */}
                            {id &&
                                <button type='button' className='btn btn-danger mx-2' onClick={() => setIsConfirmModalOpen(true)}>Delete</button>
                            }

                            {window.location.pathname == `/supervisor/${id}` && (
                                <button
                                    className="btn btn-primary"
                                    onClick={(e) => setIsEditable(!IsEditable)}
                                >
                                    {IsEditable ? "Cancel" : "Edit"}
                                </button>
                            )}
                        </div>
                    </div>

                    <form className="form theme-form">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">

                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={true}
                                            label={"first name"}
                                            type={"text"}
                                            placeholder={"Enter first name"}
                                            onchange={handleChange}
                                            name={"first_name"}
                                            value={data.first_name}
                                            disabled={IsEditable}
                                        />
                                    </div>
                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={false}
                                            label={"Middle name"}
                                            type={"text"}
                                            placeholder={"Enter middle name"}
                                            onchange={handleChange}
                                            name={"middle_name"}
                                            value={data.middle_name}
                                            disabled={IsEditable}
                                        />
                                    </div>
                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={true}
                                            label={"Last Name"}
                                            type={"text"}
                                            placeholder={"Enter last name"}
                                            onchange={handleChange}
                                            name={"last_name"}
                                            value={data.last_name}
                                            disabled={IsEditable}
                                        />
                                    </div>
                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={true}
                                            label={"Applied Job "}
                                            type={"text"}
                                            placeholder={"Enter applied job"}
                                            onchange={handleChange}
                                            name={"job_applied"}
                                            value={data.job_applied}
                                            disabled={IsEditable}
                                        />
                                    </div>
                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={false}
                                            label={"interview scheduled"}
                                            type={"datetime-local"}
                                            // placeholder={"Enter interview scheduled"}
                                            onchange={handleChange}
                                            name={"interview_scheduled"}
                                            value={data.interview_scheduled}
                                            disabled={IsEditable}
                                        />
                                    </div>
                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.interview_report_name} label={"Interview report"} type={'file'} buttonName={'Choose file'} id={'interview_report'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, interview_report: e.target.files[0] });
                                                    setInitialValue({ ...InitialValue, interview_report_name: e.target.files[0].name })
                                                }
                                            }

                                        }} isDisabled={false} />
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="row">

                                            <div className='col-3'></div>
                                            <div className='col-9'>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={currencyList}
                                                    fullWidth
                                                    value={data.currency_char}
                                                    onChange={(e, value) => { setInitialValue({ ...InitialValue, currency: value?.id }) }}
                                                    size={'small'}
                                                    renderInput={(params) => <TextField {...params} label="Choose a Currency" />}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={false}
                                            label={"Salary approved"}
                                            type={"number"}
                                            // placeholder={"Enter interview scheduled"}
                                            onchange={handleChange}
                                            name={"salary_approved"}
                                            value={data.salary_approved}
                                            disabled={IsEditable}
                                        />
                                    </div>

                                    <div className="mb-3 row">
                                        <SelectField
                                            isRequired={true}
                                            label={"Status"}
                                            options={Status}
                                            placeholder={"Choose status"}
                                            onchange={handleChange}
                                            name={"status"}
                                            value={data.status}
                                            disabled={IsEditable}
                                        />
                                    </div>


                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.offer_letter_issued_name} label={'offer letter issued'} type={'file'} buttonName={'Choose file'} id={'offer_letter_issued'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, offer_letter_issued: e.target.files[0] });
                                                    setInitialValue({ ...InitialValue, offer_letter_issued_name: e.target.files[0].name });
                                                }
                                            }

                                        }} isDisabled={false} />

                                    </div>
                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.offer_letter_signed_name} label={'offer letter signed'} type={'file'} buttonName={'Choose file'} id={'offer_letter_signed'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, offer_letter_signed: e.target.files[0] });
                                                    setInitialValue({ ...InitialValue, offer_letter_signed_name: e.target.files[0].name });
                                                }
                                            }

                                        }} isDisabled={false} />


                                    </div>
                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.passport_copy_name} label={'passport copy'} type={'file'} buttonName={'Choose file'} id={'passport_copy'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, passport_copy: e.target.files[0] });
                                                    setInitialValue({ ...InitialValue, passport_copy_name: e.target.files[0].name });
                                                }
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.certificate_copy_name} label={'certificate copy'} type={'file'} buttonName={'Choose file'} id={'certificate_copy'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, certificate_copy: e.target.files[0] })
                                                    setInitialValue({ ...InitialValue, certificate_copy_name: e.target.files[0].name })
                                                }
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.pre_medical_certificate_copy_name} label={'pre medical certificate copy'} type={'file'} buttonName={'Choose file'} id={'pre_medical_certificate_copy'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                setInitialValue({ ...InitialValue, pre_medical_certificate_copy: e.target.files[0] })
                                                setInitialValue({ ...InitialValue, pre_medical_certificate_copy_name: e.target.files[0].name })
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={false}
                                            label={"joining date"}
                                            type={"date"}
                                            // placeholder={"Enter interview scheduled"}
                                            onchange={handleChange}
                                            name={"joining_date"}
                                            value={data.joining_date}
                                            disabled={IsEditable}
                                        />
                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.pcc_copy_name} label={'pcc copy'} type={'file'} buttonName={'Choose file'} id={'pcc_copy'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, pcc_copy: e.target.files[0] })
                                                    setInitialValue({ ...InitialValue, pcc_copy_name: e.target.files[0].name })
                                                }
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.visa_copy_name} label={'visa copy'} type={'file'} buttonName={'Choose file'} id={'visa_copy'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, visa_copy: e.target.files[0] })
                                                    setInitialValue({ ...InitialValue, visa_copy_name: e.target.files[0].name })
                                                }
                                            }

                                        }} isDisabled={false} />

                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.vaccination_certificate_name} label={'vaccination certificate'} type={'file'} buttonName={'Choose file'} id={'vaccination_certificate'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, vaccination_certificate: e.target.files[0] })
                                                    setInitialValue({ ...InitialValue, vaccination_certificate_name: e.target.files[0].name })
                                                }
                                            }

                                        }} isDisabled={false} />

                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.Photo_name} label={'Photo'} type={'file'} buttonName={'Choose file'} id={'Photo'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                {
                                                    setInitialValue({ ...InitialValue, Photo: e.target.files[0] })
                                                    setInitialValue({ ...InitialValue, Photo_name: e.target.files[0].name })
                                                }
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.medical_certificate_name} label={'medical certificate'} type={'file'} buttonName={'Choose file'} id={'medical_certificate'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                setInitialValue({ ...InitialValue, medical_certificate: e.target.files[0] })
                                                setInitialValue({ ...InitialValue, medical_certificate_name: e.target.files[0].name })
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.ticket_copy_name} label={'ticket copy'} type={'file'} buttonName={'Choose file'} id={'ticket_copy'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                setInitialValue({ ...InitialValue, ticket_copy: e.target.files[0] })
                                                setInitialValue({ ...InitialValue, ticket_copy_name: e.target.files[0].name })
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <InputTextField filename={InitialValue.employee_request_form_name} label={'employee request form'} type={'file'} buttonName={'Choose file'} id={'employee_request_form'} isRequired={false} onchange={(e) => {
                                            if (e.target.files[0]) {
                                                setInitialValue({ ...InitialValue, employee_request_form: e.target.files[0] })
                                                setInitialValue({ ...InitialValue, employee_request_form_name: e.target.files[0].name })
                                            }

                                        }} isDisabled={false} />


                                    </div>

                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={true}
                                            label={"arrival date"}
                                            type={"date"}
                                            // placeholder={"Enter certificate copy"}
                                            onchange={handleChange}
                                            name={"arrival_date"}
                                            value={data.arrival_date}
                                            disabled={IsEditable}
                                        />
                                    </div>

                                    <div className="mb-3 row">
                                        <CustomTextField
                                            isRequired={true}
                                            label={"pickup details"}
                                            type={"textarea"}
                                            rows={5}
                                            // placeholder={"Enter certificate copy"}
                                            onchange={handleChange}
                                            name={"pickup_details"}
                                            value={data.pickup_details}
                                            disabled={IsEditable}
                                        />
                                    </div>


                                    {/* <div className="mb-3 row">
                                        <SelectField
                                            isRequired={true}
                                            label={"Gender"}
                                            options={genderOptions}
                                            placeholder={"Choose gender"}
                                            onchange={handleChange}
                                            name={"gender"}
                                            value={data.gender}
                                            disabled={IsEditable}
                                        />
                                    </div> */}











                                </div>
                            </div>
                        </div>

                        <div className="card-footer d-flex justify-content-between">

                            <div className="col-sm-9  d-flex ">

                            </div>

                            <div className="col-sm-3 text-end">
                                {IsSuperAdmin && (
                                    <button
                                        className="btn btn-success mr-3"
                                        type="button"
                                        disabled={!isButtonActive}
                                        onClick={handleSubmit}
                                    >
                                        {BtnLoading && <i class="fas fa-circle-notch fa-spin"> </i>}{" "}
                                        {id ? "Update" : "Submit"}
                                    </button>
                                )}
                                {/* <button className="btn btn-light" defaultValue="Cancel">{t("Reset")}</button> */}
                            </div>
                        </div>

                    </form>

                    {IsConfirmModalOpen &&
                        <ConfirmationModal title={''} message={('Are you sure you want to delete this employee details ?')} onSubmit={() => handleDelete()} onClose={() => setIsConfirmModalOpen(false)} ModalOpen={IsConfirmModalOpen} />
                    }
                </>
                {/* } */}
            </div>
        </>
    )
}

export default EmployeeDetailsTemplate