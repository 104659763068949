import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Context } from '../../components/context/context'
import Loader from '../../components/Loader/Loader'
import ConfirmationModal from '../../components/Modal/confirmationModal'
import { protocol, url } from '../../Functionalities/config'
import { addDomainInImage, ShowSuccessMessage, ShowFailedMessage, sendNotification } from '../../Functionalities/functions'
import FilterModal from '../../components/Modal/FilterModal'
import CustomModal from '../../components/Modal/CustomModal'
import { ManagePagination } from '../../Functionalities/PaginationLogic'
import TextField from '../../components/InputFields/TextField'
import SelectField from '../../components/InputFields/SelectField'
import { Download } from '../Export/download'
import { ManageAPIs } from '../../Functionalities/Logic'
import { Button } from '@mui/material'
import styled from '@emotion/styled'
import { ContentCopy } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'


const Applications = () => {
    const { state } = useLocation()
    const history = useHistory()
    const { JobPost, setJobPost } = useContext(Context)
    const [FilterData, setFilterData] = useState({ searchText: '', location: '', jobType: '' })
    const [data, setdata] = useState([])
    const [IsFilterModalOpen, setIsFilterModalOpen] = useState(false)
    const [IsConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [Prev, setPrev] = useState('')
    const [Next, setNext] = useState('')
    const [loading, setloading] = useState(true)
    const [clearFilter, setClearFilter] = useState(false)
    const [CLoneBtnLoading, setCLoneBtnLoading] = useState(false)



    useEffect(() => {
        GetApplication()
    }, [])


    useEffect(() => {
        if (clearFilter) {
            history.replace({})

            GetApplication()

        }

    }, [clearFilter])


    const GetApplication = (params) => {
        let param = params
        if (params) {
            param += `search_text=${FilterData.searchText}&location=${FilterData.location}&job_type=${FilterData.jobType}`
        } else {
            param = `search_text=${FilterData.searchText}&location=${FilterData.location}&job_type=${FilterData.jobType}`
        }

        let path = '/jobpost/'

        let result = ManageAPIs('get', path, '', param)

        result.then((res) => {
            console.log(res)
            setClearFilter(false)
            if (params?.includes('pagination=0')) {
                if (res.data) {
                    setJobPost(res.data)
                    setloading(false)


                } else {
                    setloading(false)
                    setJobPost([])
                }
            } else {
                if (res.data.results.length >= 1) {
                    setJobPost(res.data.results)


                    setNext(res.data.next)
                    setPrev(res.data.previous)
                    setloading(false)

                } else {
                    setloading(false)
                    setJobPost([])
                    setNext()
                    setPrev()
                }
            }
        })

            .catch((err) => {
                console.log(err)
            })
    }

    const HandlePrev = () => {
        let path = Prev.replace('http:', protocol.replace('//', ''))

        let result = ManagePagination(path, '')

        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setJobPost(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)

                setloading(false)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const HandleNext = () => {
        // return console.log(Next.replace("http:", protocol.replace('//', "")))
        let path = Next.replace("http:", protocol.replace('//', ""))

        let result = ManagePagination(path, '')
        result.then(function (response) {
            console.log((response.data));
            if (response.data.results) {
                setJobPost(response.data.results)
                setNext(response.data.next)
                setPrev(response.data.previous)
                setloading(false)
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }



    const HandleFilter = () => {
        GetApplication()
        setIsFilterModalOpen(false)
    }

    const educationList = [
        { value: 'UG', id: 0 },
        { value: 'PG', id: 1 },
        { value: 'DIPLOMA', id: 2 },
        { value: 'PLUS_TWO', id: 3 },
        { value: 'SSLC', id: 4 },
    ]

    const ClonePost = async (data) => {

        let skillArray = []

        for (let i = 0; i < data.skills.length; i++) {
            skillArray.push(data.skills[i].id)
        }

        setCLoneBtnLoading(true)


        let formdata = new FormData()

        formdata.append('job_name', data.job_name);
        formdata.append('pay_min_amount', data.pay_min_amount);
        formdata.append('pay_max_amount', data.pay_max_amount);

        formdata.append('skills', JSON.stringify(skillArray));
        formdata.append('min_experience', data.min_experience);
        formdata.append('description', data.description);
        formdata.append('max_experience', data.max_experience);
        // formdata.append('avatar', data.prev_avatar);
        formdata.append('requirements', data.requirements);
        formdata.append('education_requirements', educationList.find(item => item.value == data.education_requirements).id);
        formdata.append('location', data.location);
        formdata.append('currency', data.currency);
        formdata.append('responsibility', data.responsibility);


        let path = '/jobpost/'
        let result = ManageAPIs('add', path, formdata, '')

        result.then((res) => {
            console.log('add res', res)
            setCLoneBtnLoading(false)
            if (res.data.Status) {
                ShowSuccessMessage(res.data.Message)
                GetApplication()

            } else {
                ShowFailedMessage(res.data.Message)
                console.log(res.data.Message)
            }
        })


            .catch((err) => {
                console.log(err)
                setCLoneBtnLoading(false)
                // setisButtonActive(true)
            })




    }
    // console.log('>>>>filter data', FilterData.ProviderName)

    const jobTypes = [
        { value: 'FULL_TIME', id: 0 },
        { value: 'PART_TIME', id: 1 },
        { value: 'SELF_EMPLOYED', id: 2 },
        { value: 'FREELANCE', id: 3 },
        { value: 'INTERNSHIP', id: 4 },
        { value: 'TRAINEE', id: 5 },
        { value: 'CONTRACT', id: 6 },
        { value: 'TEMPORARY', id: 7 },
    ]

    return (
        <>
            <div className="card">
                <div className="card-header d-flex justify-content-between pb-0 ">
                    <div>

                        <h5>{"Job Post"} </h5>

                    </div>

                    <div >

                        <button className="btn btn-primary" onClick={() => setIsFilterModalOpen(true)}>Filter</button>
                        {/* <button className="btn btn-outline-primary" style={{ marginLeft: '.5rem' }} onClick={() => { setIsConfirmModalOpen(true); GetApplication('&pagination=0') }} >Export</button> */}
                        {/* {data.length > 0 &&
                            <Download title={'Applications'} data={data} onclick={() => GetApplication('pagination=0')} />
                        } */}
                    </div>





                </div>

                <div>
                    <div className="card-body">

                        {(FilterData?.searchText || FilterData?.location || FilterData.jobType) &&
                            <div className="d-flex align-items-center mb-4">

                                <p className="mb-0">Filter Applied</p>
                                <button className="font-primary btn  m-0" onClick={() => { setFilterData({ ...FilterData, searchText: '', location: '', jobType: '' }); setClearFilter(true) }}>
                                    <i className="fas fa-times-circle " style={{ marginRight: '.25rem' }}></i>
                                    Clear Filter
                                </button>
                            </div>
                        }

                        <div className="d-flex justify-content-between mb-3">
                            <div>

                                <Link to={'/add-job-post'} className='btn btn-primary'>Add Post</Link>

                            </div>



                            <div className="">
                                <button className='btn btn-primary' disabled={!Prev ? true : false} onClick={HandlePrev}>Prev  </button>
                                {/* {` ${currentPage}${TotalPages}`} */}
                                <button className='btn btn-primary ' style={{ marginLeft: '.25rem' }} disabled={!Next ? true : false} onClick={HandleNext}> Next</button>
                            </div>

                        </div>





                        <div className="d-flex justify-content-end">




                            {IsFilterModalOpen &&
                                <CustomModal
                                    ModalOpen={IsFilterModalOpen}
                                    title={'Filter'}
                                    onClose={() => { { setIsFilterModalOpen(false); GetApplication() } }}
                                    PrimaryButtonName={'Submit'}
                                    PrimaryButtonFunc={() => HandleFilter()}
                                >
                                    <div className="row">
                                        <div className="col-6">
                                            <TextField label={'Search '} placeholder={'Enter Job Name'} type={'text'} onchange={(e) => setFilterData({ ...FilterData, searchText: e.target.value })} value={FilterData?.searchText} />
                                        </div>
                                        <div className="col-6 ">
                                            <TextField label={'Location'} placeholder={'Enter Location'} onchange={(e) => setFilterData({ ...FilterData, location: e.target.value })} value={FilterData?.location} />
                                        </div>
                                        <div className="col-6 mt-3">
                                            <SelectField label={'Country'} placeholder={'choose a Job type'} options={jobTypes} onchange={(e) => setFilterData({ ...FilterData, jobType: e.target.value })} value={FilterData?.jobType} />
                                        </div>

                                    </div>
                                </CustomModal>
                            }
                            {IsConfirmModalOpen &&
                                <CustomModal
                                    ModalOpen={IsConfirmModalOpen}
                                    // title={'exporting'}
                                    onClose={() => { setIsConfirmModalOpen(false) }}
                                    PrimaryButtonName={'download'}
                                    ExportButton={<Download title={'Applications'} data={data} isDisabled={loading} />}
                                >
                                    <div className="row">
                                        {loading ?
                                            <Loader />
                                            :
                                            <div className='d-flex justify-content-center'>
                                                <h6>
                                                    Your file is ready to download
                                                </h6>

                                            </div>
                                        }
                                    </div>
                                </CustomModal>
                            }





                        </div>



                        <div className="table-responsive custom-scrollbar">

                            <div id="basic-3_wrapper" className="dataTables_wrapper">




                                {JobPost.length ?

                                    <table className="display dataTable" id="basic-3" role="grid" aria-describedby="basic-3_info">
                                        <thead>
                                            <tr role="row">

                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '50px' }}>{("Job Id")}</th>
                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '350px' }}>{("Job Name")}</th>
                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '200px' }}>{("Job Type")}</th>
                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '200px' }}>{("Location")}</th>
                                                {/* <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '200px' }}>{("Country")}</th> */}
                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '170px' }}>{("Experience")}</th>
                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Position: activate to sort column ascending" style={{ width: '300px' }}>{("Salary")}</th>
                                                <th className="" tabIndex={0} aria-controls="basic-3" rowSpan={1} colSpan={1} aria-label="Office: activate to sort column ascending" style={{ width: '500.9px' }}>{("Actions")}</th>


                                            </tr>
                                        </thead>
                                        <tbody>




                                            {JobPost?.map((item, idx) => (

                                                <tr role="row" className="odd" >

                                                    <td>

                                                        <p className='f-w-500 mb-0'>{item.id}</p>

                                                    </td>

                                                    <td>
                                                        <p className='f-w-500 mb-0'>{item.job_name}</p>
                                                    </td>

                                                    <td >
                                                        <p className='f-w-500 mb-0'>{item.job_type.replace('_', ' ')}</p>
                                                    </td>
                                                    <td >
                                                        <p className='f-w-500 mb-0'>{item.location}</p>
                                                    </td>
                                                    <td >
                                                        <p className='f-w-500 mb-0'>{`${item.min_experience} - ${item.max_experience} years`}</p>
                                                    </td>
                                                    <td >
                                                        <p className='f-w-500 mb-0'>{`${(item.pay_min_amount)} - ${(item.pay_max_amount)} ${item.currency && item.currency}`}</p>
                                                    </td>




                                                    <td >
                                                        <div className="d-flex justify-content-end">

                                                            {/* <LoadingButton
                                                                loading={CLoneBtnLoading}
                                                                loadingPosition="start"
                                                                startIcon={<ContentCopy />}
                                                                variant='contained'
                                                                onClick={() => ClonePost(item)}
                                                                color='error'
                                                            >
                                                                Clone
                                                            </LoadingButton> */}

                                                            {/* <Loading color='error' variant='contained' onClick={() => ClonePost(item)}>{`Clone`}</Loading> */}
                                                            <Button color='info' disabled={!item.matching_list?.length >= 1 ? true : false} variant='contained' onClick={() => history.push({ pathname: `/matching-list/${item.id}`, state: { matchingList: item.matching_list, title: `Matching users`, jobId: item.id, jobName: item.job_name, status: item.job_requests } })}>{`Matching ${item.matching_list.length > 0 ? `` : ''}`}</Button>
                                                            <Button color='success' disabled={!item.shortlisted_users?.length >= 1 ? true : false} variant='contained' onClick={() => history.push({ pathname: '/shortlisted-users', state: { shortlistedUsers: item.shortlisted_users, title: `Shortlisted users`, jobId: item.id, jobName: item.job_name, status: item.job_requests } })}>{`shortlisted ${item.shortlisted_users.length > 0 ? `` : ''}`}</Button>

                                                            <Button color='primary' disabled={item.applied_users?.length >= 1 ? false : true} variant='contained' onClick={() => history.push({ pathname: '/applied-users', state: { appliedUsers: item.applied_users, title: `Applied users`, jobId: item.id, jobName: item.job_name, status: item.job_requests } })}>{`Applied ${item.applied_users.length > 0 ? `` : ''}`}</Button>

                                                            <Link to={{ pathname: `/job-post/${item.id}` }} target={'_blank'}>
                                                                <ColorButton variant='contained' color='primary' >View</ColorButton>
                                                            </Link>

                                                        </div>
                                                    </td>

                                                </tr>
                                            ))}

                                        </tbody>


                                    </table>
                                    :
                                    <>
                                        {loading ?
                                            <Loader /> :

                                            <div className="d-flex justify-content-center">
                                                <h6>{("No Data Found")}</h6>
                                            </div>
                                        }
                                    </>
                                }



                            </div>
                        </div>

                    </div>
                </div>

                {/* {isModalOpen.value &&
                    <ConfirmationModal title={''} message={t('Are you sure you want to remove ?')} onSubmit={''} onSubmit={() => onDelete(isModalOpen.id)} onClose={() => setisModalOpen(false)} ModalOpen={isModalOpen} />
                } */}
            </div >
        </>
    )
}
export default Applications
const ColorButton = styled(Button)(({ }) => ({
    // color: theme.palette.getContrastText('#2c245d'),
    backgroundColor: '#2c245d',
    '&:hover': {
        backgroundColor: '#2c245d',
    },
}));
