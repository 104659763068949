import React, { useContext } from 'react'
import { Context } from '../context/context'
import Header from '../Header/header'
import Sidebar from '../sidebar/sidebar'

const Layout = ({ children,activePage }) => {
    const { isSidebarOpen } = useContext(Context)
    return (
        <>
            <Header />
            <section className="page-wrapper compact-wrapper compact-sidebar">
                <div className="page-body-wrapper ">
                    {isSidebarOpen ?
                    <div className="page-body" >
                        {children}
                    </div>
                    :
                    <div className="page-body" style={{marginLeft:'0'}} >
                        {children}
                    </div>
                     }
                </div>
            </section>

            <Sidebar />
        </>
    )
}

export default Layout
