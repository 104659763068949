import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Context } from '../context/context'
import Logo from '../../Assets/images/Layer 2.png'
import ConfirmationModal from '../Modal/confirmationModal'
import { protocol, version } from '../../Functionalities/config'
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal/CustomModal'
import { sendNotification, sendNotificationToSegments } from '../../Functionalities/functions'
// import { ManageProfile } from '../../pages/Profile/ProfileLogic'
import TextField from '../../components/InputFields/TextField'


const Header = () => {
    const history = useHistory()

    const { isSidebarOpen, setisSidebarOpen, setToken, IsSuperAdmin, user, setuser } = useContext(Context)
    const [menuDropdown, setMenuDropdown] = useState(false)
    const [isModalOpen, setisModalOpen] = useState(false)
    const { t } = useTranslation();
    const [data, setdata] = useState([])
    const [userdata, setuserdata] = useState()
    const [IsModalOpen, setIsModalOpen] = useState(false)
    const [NotificationData, setNotificationData] = useState({ id: '', banner_image: '', product_title: '', description: '' })


    useEffect(()=>{
        setuserdata(JSON.parse(localStorage.getItem("jobportal_data"))?.username)
        // console.log("user data",typeof(JSON.parse(localStorage.getItem("jobportal_data"))))
    },[])
    

    const handleLogout = () => {
        localStorage.removeItem("jobportal_token")
        localStorage.removeItem("user")
        history.replace("/");
        setToken()
    }
    
    // useEffect(() => {
    //     // window.location.replace('/')
    //     history.push('/')
    // }, [])


    const SendPushNotification = () => {
        let _data = { title: NotificationData.product_title, body: NotificationData.description, booking_id: "", post_id: NotificationData.id }
        
        sendNotificationToSegments(_data,"","")

    }

    // console.log("NotificationData", NotificationData);

    return (
        <header className="page-wrapper compact-wrapper compact-sidebar" style={{zIndex: '999'}}>

            <div className="page-main-header">
                <div className="main-header-right row m-0">
                    <div className="main-header-left">
                        <div className="logo-wrapper">
                            <a data-bs-original-title title><img className="img-fluid"
                                style={{ maxWidth: '6rem', width: '100%' }}
                                src={Logo}
                                alt />
                            </a>
                        </div>
                        {/* <div className="dark-logo-wrapper"><a href="index.html" data-bs-original-title title><img className="img-fluid" src="../assets/images/logo/dark-logo.png" alt /></a></div> */}
                        <div className="toggle-sidebar" onClick={() => setisSidebarOpen(!isSidebarOpen)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-align-center status_toggle middle" id="sidebar-toggle" checked="true">
                                <line x1={18} y1={10} x2={6} y2={10} />
                                <line x1={21} y1={6} x2={3} y2={6} />
                                <line x1={21} y1={14} x2={3} y2={14} />
                                <line x1={18} y1={18} x2={6} y2={18} />
                            </svg>
                            
                        </div>
                            
                      
                            
                    </div>
                    <div className="left-menu-header col " style={{paddingLeft:0}}>
                        {
                            <button className="btn px-2" disabled>{userdata}</button>
                        }
                        
                        {/* <div>
                            <h6 className="m-0 text-capitalize">"jahsfdajhsdv"</h6>
                        </div> */}
                    </div>
                    <div className="nav-right col pull-right right-menu p-0">
                        <ul className={menuDropdown ? "nav-menus open" : "nav-menus"}>

                            {/* <button className={`btn ${(i18n.language == "fr") ? "btn-primary" : "btn-primary-light"} m-r-10`} onClick={() => changeLanguage('fr')}>fr</button>
                            <button className={`btn ${i18n.language == "en" ? "btn-primary" : "btn-primary-light"} m-r-10`} onClick={() => changeLanguage('en')}>en</button> */}


                            {/* {user != 'supervisor' && <div className="d-flex align-items-center">
                                <button type="button" className="btn btn-light  mx-1" title='Send Notification' onClick={() => {
                                    setIsModalOpen(true);

                                }}><i class="far fa-paper-plane"></i></button>
                            </div>} */}
                        
                            
                            <div className="d-flex align-items-center">
                                <p className="mb-0" style={{ marginRight: '.75rem' }}>{version}</p>
                            </div>
                        
                            
                            {/* <Link to="/profile" className="btn btn-primary-light" style={{ marginRight: '1rem' }}><i class="fas fa-user"></i> {t('Profile')}</Link> */}

                            {/* <h6 className="mb-0 text-capitalize " style={{marginRight:'1rem'}}>{IsSuperAdmin ? 'Admin' : 'Super visor' ? '  Front Office Staff, Lab Staff' : data.is_front_office_staff ? 'Front office staff' : data.is_lab_staff ? 'Lab staff' : data.is_staff && ' staff'}</h6> */}


                            <li className="onhover-dropdown p-0">
                                <button className="btn btn-primary-light" onClick={() => setisModalOpen(true)} type="button" data-bs-original-title title ><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" /><polyline points="16 17 21 12 16 7" /><line x1={21} y1={12} x2={9} y2={12} /></svg>{t('Log out')}</button>
                            </li>
                        </ul>
                    </div>
                    <div className="d-lg-none mobile-toggle pull-right w-auto" style={{ cursor: 'pointer' }} onClick={() => setMenuDropdown(!menuDropdown)}><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-more-horizontal"><circle cx={12} cy={12} r={1} /><circle cx={19} cy={12} r={1} /><circle cx={5} cy={12} r={1} /></svg></div>
                </div>

                {isModalOpen &&
                    <ConfirmationModal title={''} message={t('Are you sure you want to logout ?')} onSubmit={() => handleLogout()} onClose={() => setisModalOpen(false)} ModalOpen={isModalOpen} />
                }
                {IsModalOpen &&
                    <CustomModal
                        ModalOpen={IsModalOpen}
                        title={'Notification'}
                        modalFor={'send_notification_modal'}
                        onClose={() => setIsModalOpen(false)}
                        PrimaryButtonName={'Send'}
                        // ModalData={NotificationData}
                        PrimaryButtonFunc={() => SendPushNotification()}

                        // ImageOnchange={e => setNotificationData({ ...NotificationData, banner_image: e.target.value })}
                        // ImageValue={NotificationData.banner_image}
                        // TitleOnchange={e => setNotificationData({ ...NotificationData, product_title: e.target.value })}
                        // TitleValue={NotificationData.product_title}
                        // DescOnchange={e => setNotificationData({ ...NotificationData, description: e.target.value })}
                        // DescValue={NotificationData.description}
                    // onCancel={() => setNotificationData({ id: '', banner_image: '', product_title: '', description: '' })}
                    >
                    <div className="row">
                        {/* <div className="col-12 mt-3">
                            <TextField label={'Image'} placeholder={'Enter image url'} onchange={e => setNotificationData({ ...NotificationData, banner_image: e.target.value })} value={NotificationData.banner_image} />
                        </div> */}
                        <div className="col-12 mt-3">
                            <TextField label={'Title*'} placeholder={'Enter your title'} onchange={e => setNotificationData({ ...NotificationData, product_title: e.target.value })} value={NotificationData.product_title} />
                        </div>
                        <div className="col-12 mt-3">
                            <TextField label={'Description*'} placeholder={'Enter your Description'} onchange={e => setNotificationData({ ...NotificationData, description: e.target.value })} value={NotificationData.description} />
                        </div>
                        {/* <div class="mt-5">
                            <p>are you assured to send notification to user?</p>
                            <div class="col-sm-12">
                                <input type="text" class="form-control" placeholder="type here....."/>
                                    <span class="f-w-400">type <span class="f-w-600">SEND</span> to confirm</span>
                            </div>
                        </div> */}
                    </div>
                    </CustomModal>
                }


            </div>

        </header>
    )
}

export default Header
