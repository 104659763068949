import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const Download = (props) => {

    if (props.title == 'employeeDetails') {
        console.log(props.data);
        console.log(props.data.length);
        let export_data = []
        for (let i = 0; i < props.data.length; i++) {
            var newData = {}


            newData.id = props.data[i].id
            newData.first_name = props.data[i].first_name
            newData.middle_name = props.data[i].middle_name
            newData.last_name = props.data[i].last_name
            newData.job_applied = props.data[i].job_applied
            newData.interview_scheduled = props.data[i].interview_scheduled
            newData.status = props.data[i].status
            newData.salary_approved = props.data[i].salary_approved
            newData.currency = props.data[i].currency_char
            newData.joining_date = props.data[i].joining_date
            newData.arrival_date = props.data[i].arrival_date
            newData.pickup_details = props.data[i].pickup_details
            export_data.push(newData)
        }
        console.log({ export_data });
        return (
            <ExcelFile filename={props.title} element={<button className="btn btn-primary" onClick={props.onclick} disabled={props.isDisabled} style={{ marginLeft: '.25rem' }}>Download File</button>}>

                <ExcelSheet data={export_data} name={props.title}>
                    <ExcelColumn label="Id" value='id' />
                    <ExcelColumn label="First Name" value='first_name' />
                    <ExcelColumn label="Middle Name" value='middle_name' />
                    <ExcelColumn label="Last Name" value='last_name' />
                    <ExcelColumn label="Job Applied" value='job_applied' />
                    <ExcelColumn label="Interview Scheduled" value='interview_scheduled' />
                    <ExcelColumn label="Status" value='status' />
                    <ExcelColumn label="Salary Approved" value='salary_approved' />
                    <ExcelColumn label="Currency" value='currency' />
                    <ExcelColumn label="Joining Date" value='joining_date' />
                    <ExcelColumn label="Arrival Date" value='arrival_date' />
                    <ExcelColumn label="Pickup Details" value='pickup_details' />
                </ExcelSheet>
            </ExcelFile>
        );
    }

}