import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../components/context/context'
import { url } from '../../Functionalities/config'
import { ShowFailedMessage, ShowSuccessMessage } from '../../Functionalities/functions'
import { useTranslation } from 'react-i18next'
import CustomModal from '../../components/Modal/CustomModal'
import TextField from '../../components/InputFields/TextField'


const SettingsForm = ({ data, API }) => {

    // console.log(data)
    const { token } = useContext(Context)
    const { t } = useTranslation()
    const [Value, setValue] = useState(data.value)

    const [BtnLoading, setBtnLoading] = useState(false)
    const [IsModalOpen, setIsModalOpen] = useState(false)

    const [Data, setData] = useState({ id: data.id, name: data.name, description: data.description, value: data.value })

    useEffect(() => {
        setValue(data.value)
    }, [API])

    const handleSubmit = () => {
        setBtnLoading(true)
        // console.log("axs",Data.id , Value, url, token)
        // return

        var axios = require('axios');
        var data = new FormData();
        data.append('id', Data.id);
        data.append('value', Data.value);

        var config = {
            method: 'put',
            url: url + '/settings/',
            headers: {
                'Authorization': `Token ${token}`,
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(response)
                if (response.data.Status) {
                    setBtnLoading(false)
                    ShowSuccessMessage(response.data.Message)
                    setIsModalOpen(false)
                    API()

                } else {
                    setBtnLoading(false)
                    ShowFailedMessage(response.data.Message)
                }
            })
            .catch(function (error) {
                setBtnLoading(false)
                console.log(error);
            });
    }

    return (
        <div>
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">
                    {Data?.name.replaceAll('_', ' ')}

                    <i class="fas fa-info-circle mx-3" title={Data?.description} style={{ cursor: 'pointer' }}></i>
                </label>

                <div className="col-sm-6">
                    <input disabled className="form-control digits" type="text" value={Value} />
                </div>
                <div className="col-sm-3">
                    <div>


                        <button className="btn btn-light  " type="button" onClick={() => setIsModalOpen(true)}
                        >Edit</button>


                    </div>
                </div>
            </div>

            {IsModalOpen &&
                <CustomModal

                    ModalOpen={IsModalOpen}
                    title={'Edit ' + Data.name.replaceAll('_', ' ')}
                    modalFor={'edit_settings'}
                    onClose={() => setIsModalOpen(false)}
                    PrimaryButtonName={'Submit'}
                    ModalData={Data}
                    PrimaryButtonFunc={handleSubmit}
                    id={Data.id}

                >
                    <div className="row">
                        <div className="col-12">
                            <TextField label={Data?.name.replaceAll('_', ' ')} placeholder={`Enter ${Data?.name.replaceAll('_', ' ')}`} type={'text'} onchange={(e) => setData({ ...data, name: e.target.value })} value={data?.name} />
                        </div>
                        {/* <div className="col-12 mt-3">
                            <TextField label={'Description'} placeholder={'Enter Description'} onchange={(e) => setData({ ...data, description: e.target.value })} value={data?.description} />
                        </div> */}
                        <div className="col-12 mt-3">
                            <TextField label={'Value'} placeholder={'Enter Value'} onchange={(e) => setData({ ...data, value: e.target.value })} value={Data.value} />
                        </div>
                    </div>
                </CustomModal>
            }
        </div>
    )
}

export default SettingsForm
