

export const protocol = 'https://'
export const host = "wwwjicit.com"; //live

// export const protocol = 'http://'
// export const host = "174.2.0.31:8000"; //local
// export const host = "34.123.238.119"; //debug
// export const host = "career.job-in-arabia.com"; //live



export const version = 'V 1.1.1'
// export const url = protocol + host
export const url = protocol + host

// export const OneSignalAppID = '3aa016fb-b951-4c41-9edd-2e246b5187e2'
// export const OneSignalAppID = '3aa016fb-b951-4c41-9edd-2e246b5187e2'
export const RestAPI = ''

