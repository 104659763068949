import { ShowFailedMessage } from "./functions"

const BlankValidation = (name, item) => {
    // alert('1')
    // console.log('blank validate', name, item);
    if (item === "" || item === null) {
        ShowFailedMessage(name.replace('_', ' ') + ' cannot be empty')
        //   showMessage(name + " cannot blank! ", "warning", "Blank Validation Failed", "top", "top-right", 5000)
        return 0
    } else {
        //   showMessage(name + " validation passed! ", "success", "Validation Success", "bottom", "bottom-left", 2000)
        return 1
    }
}

export const userValidation = (data, validatingData) => {
    console.log("validating  Data ", data)
    let validation = 1;
    let blank_validation = validatingData
    // let blank_validation = ['referral_code', 'dob', 'new_photo', 'first_name', 'email', 'remaining_coin', 'username', 'gender', 'new_adhar_image1', 'new_adhar_image2',]

    for (var i = 0; i < blank_validation.length; i++) {
        if (BlankValidation(blank_validation[i], data[blank_validation[i]])) { console.log("Validation passed for ", blank_validation[i]) }
        else { validation = 0; break; }
    }

    return validation;
}
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

// export const lengthValidation = (item, length, Name) => {
//     if (item?.length <= length - 1) {
//         ShowFailedMessage(`${Name} Must be at least ${length} number`)
//         return false
//     }else{
//         return true
//     }
// }

export const ConvertException = (message) => {

    let text = message;
    const newMessage = text.split("'");
    // console.log(newMessage)
    // show = []
    for (let i = 0; i < newMessage.length; i++) {

        if (newMessage[i].includes("[ErrorDetail(string=")) {

            ShowFailedMessage(newMessage[i + 1])
        }
    }
}