import React, { useEffect } from 'react'
import { Link, useHistory, Redirect } from 'react-router-dom'
// import backgroundImg from '../Assets/images/2.jpg'
// import backgroundImg from '../../Assets/images/ctmlogin.jpg'
import backgroundImg from '../../Assets/images/Layer 2.png'
import { Context } from '../../components/context/context';
import { useContext } from 'react';
import { useState } from 'react';
import { url } from '../../Functionalities/config';
import axios from 'axios';
import { ShowFailedMessage, ShowSuccessMessage, validateEmail } from '../../Functionalities/functions';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../components/Modal/CustomModal';
import { ConvertException } from '../../Functionalities/Validations';

// import Logo from '../../Assets/images/logo.png'

const Login = () => {

    const { t, i18n } = useTranslation()

    console.log(i18n)

    const { setToken, setuser, forWho, setforWho, setIsSuperAdmin } = useContext(Context)


    const history = useHistory();


    const [ShowPassord, setShowPassord] = useState(false)

    const [userData, setUserData] = useState({
        username: "",
        password: ""
    })

    const [BtnLoading, setBtnLoading] = useState(false)
    const [forgotBtnLoading, setForgotBtnLoading] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        setBtnLoading(true)


        var formData = new FormData()
        formData.append("username", userData.username)
        formData.append("password", userData.password)

        var config = {
            method: 'post',
            url: url + '/user/login/',
            data: formData
        };
        console.log('GET API config', config)

        await axios(config)
            .then(function (response) {
                console.log("GET API response", response)
                if (response.data.Status) {
                    if (response.data.Data.role <= 1) {


                        localStorage.setItem("jobportal_token", response.data.Data.token)
                        localStorage.setItem("jobportal_data", JSON.stringify(response.data.Data))
                        setIsSuperAdmin(response.data.Data.is_superuser)
                        setToken(response.data.Data.token)

                        history.push('/job-post')


                        ShowSuccessMessage(response.data.Message)
                        setBtnLoading(false)
                        setuser(response.data.Data.user_type)
                    } else {
                        ShowFailedMessage('Unauthorised user, you dont have permission to access this')
                        setBtnLoading(false)
                    }

                }
                else if (response.data.Message.includes('Error occured')) {
                    // console.log(response.data.Data.referral_code)
                    // console.log(response.data.Data)
                    ConvertException(response.data.Message)
                    setBtnLoading(false)

                }

                else {
                    setBtnLoading(false)
                    ShowFailedMessage(response.data.Message)
                }
            })
            .catch(function (error) {
                console.log("GET API error", error)
                let err = JSON.stringify(error)
                setBtnLoading(false)


                if (err.match('400')) {
                    ShowFailedMessage('Invalid Credential')

                }
            });

    }



    const onChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }









    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-7 b-center bg-size login-image"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                                // backgroundImage: `url(${backgroundImg})`,
                                // backgroundSize: 'cover', backgroundPosition: 'center center', display: 'block'
                            }}
                        >
                            <img className="bg-img-cover bg-center" src={backgroundImg} alt="looginpage" style={{ height: '4rem' }} />
                        </div>
                        <div className="col-xl-5 p-0">
                            <div className="login-card">
                                <form className="theme-form login-form">
                                    {/* <div className="d-flex justify-content-evenly mb-5">
                                        <button type="button" style={{marginLeft: 0}} className={`btn ${(i18n.language == "fr") ? "btn-primary" : "btn-primary-light"}`} onClick={(e) => changeLanguage('fr', e)}>fr</button>      
                                        <button type="button" style={{marginLeft: 0}} className={`btn ${(i18n.language == "en" ) ? "btn-primary" : "btn-primary-light"}`} onClick={(e) => changeLanguage('en', e)}>en</button>
                                    </div> */}
                                    <h4>{t("Login")}</h4>
                                    <h6>{t("Welcome back! Log in to your account.")}</h6>
                                    <div className="form-group">
                                        <label>{t("Username")}</label>
                                        <div className="input-group"><span className="input-group-text"><i className="far fa-user"></i></span>
                                            <input name="username" onChange={onChange} value={userData.username} className="form-control" type="text" required placeholder="eg:john" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>{t("Password")}</label>
                                        <div className="input-group"><span className="input-group-text"><i className="fas fa-lock" /></span>
                                            <input name="password" onChange={onChange} value={userData.password} className="form-control" type={ShowPassord ? 'text' : "password"} required placeholder="*********" />
                                            <div className="show-hide">
                                                {ShowPassord ?
                                                    <button type='button' className='btn px-1' onClick={() => setShowPassord(!ShowPassord)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width={15} fill={'current-color'}>
                                                            <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                                                        </svg>
                                                    </button>

                                                    :
                                                    <button type='button' className='btn px-1' onClick={() => setShowPassord(!ShowPassord)} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width={15} fill={'current-color'}>
                                                            <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                                                        </svg>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <div className="checkbox">
                                            <input id="checkbox1" type="checkbox" />
                                            <label className="text-muted" htmlFor="checkbox1">Remember password</label>
                                        </div><a className="link" href="forget-password.html">Forgot password?</a>
                                    </div> */}
                                    <div className="form-group">
                                        <div >
                                            <button style={{ width: '100%' }} type="submit" onClick={handleLogin} className="btn btn-primary btn-block" > {BtnLoading && <i class="fas fa-circle-notch fa-spin"> </i>} {t("Sign in")}</button>
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <div >
                                            <div onClick={forgotPasswordModal} className="btn btn-secondary btn-block" >{t("Forgot Password ?")}</div>
                                        </div>
                                    </div> */}
                                    {/* <div className="login-social-title">
                                        <h5>Sign in with</h5>
                                    </div> */}
                                    {/* <div className="form-group">
                                        <ul className="login-social">
                                            <li><a href="https://www.linkedin.com/login" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" /><rect x={2} y={9} width={4} height={12} /><circle cx={4} cy={4} r={2} /></svg></a></li>
                                            <li><a href="https://www.linkedin.com/login" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" /></svg></a></li>
                                            <li><a href="https://www.linkedin.com/login" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" /></svg></a></li>
                                            <li><a href="https://www.instagram.com/login" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram"><rect x={2} y={2} width={20} height={20} rx={5} ry={5} /><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" /><line x1="17.5" y1="6.5" x2="17.5" y2="6.5" /></svg></a></li>
                                        </ul>
                                    </div> */}
                                    {/* <p>Don't have account?<a className="ms-2" href="sign-up.html">Create Account</a></p> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <CustomModal 
                    message={"Password reset"}
                    onSubmit={resetPassword}
                    onClose={() => {setisModalOpen(false)}}
                    ModalOpen={isModalOpen} 
                    mode={modalMode}
                    forgotBtnLoading={forgotBtnLoading}
                /> */}
            </section>

        </div>
    )
}

export default Login
