import React from 'react'

const InputRadio = ({label, value, onchange, disabled}) => {
    return (
        <div className="mb-3 row">
            <div className='col-3 ' >
                <label className='col-form-label'>{label}</label>
            </div>

            <div className="col-6">
                <label class="switch">
                    <input type="checkbox" checked={value} onChange={onchange} disabled={disabled} /><span class="switch-state"></span>
                </label>
            </div>
        </div>
    )
}

export default InputRadio