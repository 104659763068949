import { useEffect, useState } from 'react'

const UseForm = (InitialValue, ClearState, setClearState, ) => {
    const [value, setvalue] = useState(InitialValue)

    useEffect(() => {
        setvalue(InitialValue)
    }, [InitialValue])


    if (ClearState) {
        // console.log('clearing state')
        setClearState(false)
        setvalue(InitialValue)
    }
    return [value, (e) => {
        // console.log()
        if (e.target?.name == 'mobile') {

            setvalue({ ...value, [e.target?.name]: e.target?.value })
        } else {

            setvalue({ ...value, [e.target?.name]: e.target?.value })
        }

    }
    ]

}

export default UseForm


